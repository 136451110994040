import type { ReactElement } from 'react';
import classnames from 'classnames';

import { ProgressBar, Text, VSpacing } from '@hh.ru/magritte-ui';
import { BoltOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import type { ApplicantActivityContentSize } from 'lux/components/IndexPageApplicant/ApplicantActivityCard/ApplicantActivity/types';
import {
    IconSizeMap,
    MAX_ACTIVITY_SCORE,
    PercentTypographyMap,
    TitleTypographyMap,
} from 'lux/components/IndexPageApplicant/ApplicantActivityCard/ApplicantActivity/utils';

import styles from './styles.module.less';

const TrlKeys = {
    title: 'applicantActivityMeter.title',
};

interface ApplicantActivityProps {
    score?: number;
    size?: ApplicantActivityContentSize;
    right?: ReactElement | null;
}

const ApplicantActivity: TranslatedComponent<ApplicantActivityProps> = ({ trls, score = 0, size = 'small', right }) => {
    const progress = Math.min(score, MAX_ACTIVITY_SCORE);
    const percent = `${Math.round((progress / MAX_ACTIVITY_SCORE) * 100)}%`;

    const content = (
        <section className={classnames(styles.content, { [styles.contentCompact]: right })}>
            <Text Element="span" typography={TitleTypographyMap[size]}>
                {trls[TrlKeys.title]}
            </Text>
            <div
                className={classnames(styles.extra, {
                    [styles.extraSmall]: size === 'small',
                    [styles.extraLarge]: size === 'large',
                })}
            >
                <BoltOutlinedSize24
                    width={IconSizeMap[size]}
                    height={IconSizeMap[size]}
                    initial="warning-secondary"
                    fill="var(--magritte-ui-icon-color)"
                />
                <Text Element="span" typography={PercentTypographyMap[size]} style="secondary">
                    {percent}
                </Text>
            </div>
        </section>
    );

    return (
        <article>
            {right ? (
                <div className={styles.group}>
                    {content}
                    {right}
                </div>
            ) : (
                content
            )}
            <VSpacing default={16} />
            <ProgressBar progress={progress} max={MAX_ACTIVITY_SCORE} style="special" />
        </article>
    );
};

export default translation(ApplicantActivity);
