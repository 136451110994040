// tempexp_27495_file
import Column from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdvertisementAgreement from 'lux/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import translation from 'lux/components/translation';

import SignUpForm, { SignUpSignFormBaseProps } from 'lux/components/SignupModal/Form/steps/sign-up/SignUpForm';

const TrlKeys = {
    header1: 'signUpStep.header.1',
    header2: 'signUpStep.header.2',
};

export type SignUpStepProps = SignUpSignFormBaseProps;

const SignUpStep: TranslatedComponent<SignUpSignFormBaseProps> = ({ trls, ...formProps }) => {
    const showAdvertisementAgreement = useShowAdvertisementAgreement();

    return (
        <SignUpForm
            render={({
                renderFirstNameField,
                renderLastNameField,
                renderAccountDeleteInactive,
                renderSubmit,
                renderAdvertisementAgreement,
            }) => {
                return (
                    <>
                        <div>
                            <Text size={TextSize.Large} strong>
                                {trls[TrlKeys.header1]}
                                <br />
                                {trls[TrlKeys.header2]}
                            </Text>
                            <VSpacing base={4} />
                            {renderFirstNameField()}
                            <VSpacing base={4} />
                            {renderLastNameField()}
                            <VSpacing base={6} />
                            {renderAccountDeleteInactive()}
                            {renderSubmit()}
                            {showAdvertisementAgreement && (
                                <div>
                                    <Column l="5" m="5" s="5" xs="4" container>
                                        <VSpacing base={5} />
                                        {renderAdvertisementAgreement()}
                                    </Column>
                                </div>
                            )}
                        </div>
                    </>
                );
            }}
            isSignupMain
            {...formProps}
        />
    );
};

export default translation(SignUpStep);
