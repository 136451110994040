// tempexp_27495_file
import { FC } from 'react';

import Modal from 'bloko/blocks/modal';

import ApplicantSignInSignUpFlow from 'lux/components/SignupModal/ApplicantSignInSignUpFlow';

import SignInStep from 'lux/components/SignupModal/Form/steps/sign-in/SignInStep';
import SignUpStep from 'lux/components/SignupModal/Form/steps/sign-up/SignUpStep';
import VerificationStep from 'lux/components/SignupModal/Form/steps/verification/VerificationStep';

import styles from './sign-up-modal.less';

const SignupModal: FC<{ opened: boolean; onClose: () => void; onSignup: () => void }> = ({
    opened,
    onClose,
    onSignup,
}) => {
    return (
        <div>
            <Modal visible={opened} onClose={onClose} useBottomSheet>
                <div className={styles.wrapper}>
                    <ApplicantSignInSignUpFlow
                        SignIn={SignInStep}
                        SignUp={SignUpStep}
                        Verification={VerificationStep}
                        onSignup={onSignup}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default SignupModal;
