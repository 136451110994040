import { FC } from 'react';

import AdsExternalBanner from 'lux/components/Banner/AdsExternalBanner';
import { BannerPlace } from 'lux/models/banners';
import { useSelector } from 'lux/modules/useSelector';

interface Props {
    isMobile?: boolean;
}

const WorkInCompanyBanner: FC<Props> = ({ isMobile }) => {
    const workInCompanyBanners = useSelector(
        (state) =>
            state.banners[isMobile ? BannerPlace.INDEX_WORK_IN_COMPANY_MOBILE : BannerPlace.INDEX_WORK_IN_COMPANY]
    );

    if (!workInCompanyBanners?.[0]) {
        return null;
    }

    return (
        <div className="work-in-company-banner">
            <AdsExternalBanner {...workInCompanyBanners[0]} />
        </div>
    );
};

export default WorkInCompanyBanner;
