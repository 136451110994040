import { FC } from 'react';

import { Cell, CellText, Link as MagritteLink, Tag } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import NumberFormatter from 'bloko/common/numberFormatter';

import { ProfessionsListProfession } from 'lux/models/professionsList';

import { getProfessionLink } from './utils';

interface BottomSheetItemProps {
    item: ProfessionsListProfession;
    parentItem: ProfessionsListProfession;
    index: number;
}

const BottomSheetItem: FC<BottomSheetItemProps> = ({ item, parentItem, index }) => {
    return (
        <Cell right={item.count ? <Tag>{NumberFormatter.format(String(item.count))}</Tag> : undefined}>
            <CellText>
                <MagritteLink
                    style="neutral"
                    Element={Link}
                    to={getProfessionLink(parentItem, item)}
                    additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                    isSeoLink
                    data-qa="profession-item-title"
                    data-page-analytics-value={item.name}
                    data-page-analytics-position={index}
                    data-page-analytics-event="vacancy_rainbow_catalog_item_link.rainbowLink"
                >
                    {item.name}
                </MagritteLink>
            </CellText>
        </Cell>
    );
};

export default BottomSheetItem;
