import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import getTranslationsFor from 'lux/components/IndexPageEmployer/components/EmployerEventList/getTranslationsFor';
import translation from 'lux/components/translation';
import { ServiceEmployerDescription } from 'lux/models/oldEmployer/employerBillingSummary';

interface BalanceOptionsTooltipItemProps {
    service: ServiceEmployerDescription;
}

const Trls = {
    allProfRoleGroups: 'billing_professional_role_group.0',
    areaAll: 'employer.price.area.all',
};

const BalanceOptionsTooltipItem: TranslatedComponent<BalanceOptionsTooltipItemProps> = ({ service, trls }) => (
    <div className="purchased-service" data-qa={`purchased-service-${service.code}`}>
        <div className="purchased-service-name">
            <Text Element="p" strong>
                {service.tariffNameTrl ? service.tariffNameTrl : service.availableServicesTrl}
            </Text>
            <VSpacing base={2} />
            <Text Element="p">{getTranslationsFor(service.areaIdsTrl, trls[Trls.areaAll])}</Text>
            <Text>{getTranslationsFor(service.profRoleGroupIdsTrl, trls[Trls.allProfRoleGroups])}</Text>
        </div>
        <div className="purchased-service-count">{service.count}</div>
    </div>
);

export default translation(BalanceOptionsTooltipItem);
