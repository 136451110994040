import { FC, PropsWithChildren } from 'react';

import { GridColumn, GridRow } from '@hh.ru/magritte-ui';

import AdsExternalBanner from 'lux/components/Banner/AdsExternalBanner';
import BannerWrapper from 'lux/components/IndexPageEmployer/components/BannerWrapper';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

import Calendar from 'lux/components/IndexPageEmployer/components/Calendar';

const CalendarWithBanners: FC<PropsWithChildren> = () => {
    const banners = useSelector((state) => state.banners);
    const isZp = useIsZarplataPlatform();

    return (
        <GridRow>
            <GridColumn xs={0} s={8} m={8} l={6}>
                <Calendar />
            </GridColumn>

            {!isZp && (
                <GridColumn xs={0} s={8} m={4} l={3} flexibleContent>
                    <GridRow>
                        {banners.calendar?.map((banner) => (
                            <BannerWrapper key={banner.id} id={banner.id}>
                                <AdsExternalBanner {...banner} />
                            </BannerWrapper>
                        ))}
                    </GridRow>
                </GridColumn>
            )}
        </GridRow>
    );
};

export default CalendarWithBanners;
