import { ReactNode, RefObject, useEffect, useRef, useState, FC } from 'react';
import classnames from 'classnames';

import scrollTop from 'Utils/ScrollTop';
import translation from 'lux/components/translation';

const SCROLL_OFFSET_TOP = 150;
const SCROLL_OFFSET_BOTTOM = 200;

interface RainbowDropProps {
    tileIndex: number;
    activeDropIndex: number;
    activeTileContent: ReactNode;
    previousActiveTileIndex: number;
    isXsOnly?: boolean;
    seoContent?: ReactNode;
}

const scrollToDrop = (dropRef: RefObject<HTMLDivElement>) => {
    if (!dropRef.current) {
        return;
    }
    const dropBoundingClientTop = dropRef.current.getBoundingClientRect().top;
    const dropTopOffsetLower = dropBoundingClientTop + SCROLL_OFFSET_BOTTOM;
    if (dropTopOffsetLower > window.innerHeight) {
        scrollTop({ top: window.scrollY + (dropTopOffsetLower - window.innerHeight) });
    }
    const dropTopOffsetUpper = dropBoundingClientTop - SCROLL_OFFSET_TOP;
    if (dropTopOffsetUpper < 0) {
        scrollTop({ top: window.scrollY + dropTopOffsetUpper, speed: 0 });
    }
};

const RainbowDrop: FC<RainbowDropProps> = ({
    tileIndex,
    activeDropIndex,
    activeTileContent,
    previousActiveTileIndex,
    isXsOnly,
    seoContent,
}) => {
    const dropRef = useRef<HTMLDivElement>(null);
    const dropInnerRef = useRef<HTMLDivElement>(null);

    const [containerHeight, setContainerHeight] = useState<string>('0px');

    const content = activeDropIndex === tileIndex ? activeTileContent : null;
    const showDrop = Boolean(content);

    useEffect(() => {
        if (!showDrop) {
            // close without animation
            setContainerHeight(`0px`);
            return;
        }

        if (previousActiveTileIndex === -1) {
            // open with animation
            const contentHeight = dropInnerRef.current?.clientHeight;
            setContainerHeight(`${String(contentHeight)}px`);

            // for correct resize adaptation after transition
            const onTransitionEnd = () => {
                setContainerHeight('auto');
                dropRef.current?.removeEventListener('transitionend', onTransitionEnd);
            };
            dropRef.current?.addEventListener('transitionend', onTransitionEnd);
        } else {
            // open without animation
            setContainerHeight(`auto`);
        }
        scrollToDrop(dropRef);
    }, [showDrop, previousActiveTileIndex, tileIndex, activeDropIndex]);

    return (
        <div
            className={classnames('dashboard-tiles-item-drop-container', {
                // Ботам всегда показываем seoContent
                // Кроме случая с десктопным экраном и наличием предложения вакансий
                'dashboard-tiles-item-drop-container_visible': !isXsOnly && (!!seoContent || showDrop),
                'dashboard-tiles-item-drop-container_xs-visible': isXsOnly && !!seoContent,
            })}
            style={{ height: containerHeight }}
            data-qa={`professions-drop-desktop ${showDrop ? 'professions-drop-desktop-active' : ''}`}
            data-rainbow-catalog-drop-active={showDrop ? 'true' : 'false'}
            ref={dropRef}
        >
            <div className="dashboard-tiles-item-drop-container-inner" ref={dropInnerRef}>
                {seoContent || content}
            </div>
        </div>
    );
};

export default translation(RainbowDrop);
