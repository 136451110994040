import { useEffect, useRef } from 'react';

import resumeHasBeenUpdatedCardShown from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-26342/resume_has_been_updated_card_shown';
import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import Card, { CardType } from 'lux/components/IndexPageApplicant/Actions/Card';
import { useCanUpdateResumes } from 'lux/components/IndexPageApplicant/Actions/useCanUpdateResumes';

const TrlKeys = {
    title: 'index.applicant.actions.enableResumeAutoUpdate.title',
    link: 'index.applicant.actions.enableResumeAutoUpdate.link',
    linkRedesign: 'index.applicant.actions.enableResumeAutoUpdate.link.redesign',
    linkShort: 'index.applicant.actions.enableResumeAutoUpdate.link.short',
    description: 'index.applicant.actions.enableResumeAutoUpdate.description',
};

const EnableResumeAutoUpdate: TranslatedComponent = ({ trls }) => {
    const canUpdateResumes = useCanUpdateResumes();
    const autoUpdateAvailable = useSelector((state) => state.autoUpdateAvailableForAnyResume);
    const enableResumeAutoUpdateCardRef = useRef(null);
    const { isXS } = useBreakpoint();

    useEffect(() => {
        if (enableResumeAutoUpdateCardRef.current) {
            resumeHasBeenUpdatedCardShown(enableResumeAutoUpdateCardRef.current);
        }
    }, []);

    if (canUpdateResumes || !autoUpdateAvailable) {
        return null;
    }

    const linkText = isXS ? TrlKeys.linkShort : TrlKeys.linkRedesign;

    return (
        <Card
            dataQa="applicant-index-nba-action_enable-resume-autoupdate"
            caption={trls[TrlKeys.title]}
            description={trls[TrlKeys.description]}
            linkText={trls[linkText]}
            type={CardType.AutoUpdate}
            linkTo="/applicant/services/findjob"
            cardRef={enableResumeAutoUpdateCardRef}
            onClick={() => {
                buttonClick({ buttonName: 'update_automatically_card' });
            }}
        />
    );
};

export default translation(EnableResumeAutoUpdate);
