import { FC } from 'react';

import ContainerForMicroFrontend from 'lux/components/CodeInjector/ContainerForMicroFrontend';
import { useSelector } from 'lux/modules/useSelector';

const WIDGET_PLACE_NAME = 'renewalServiceWidget';

export const useRenewalServiceHasContent = (): boolean => {
    const renewalWidgetStatus = useSelector(({ microFrontends }) => microFrontends[WIDGET_PLACE_NAME]);
    return !!renewalWidgetStatus;
};

const RenewalServiceWidget: FC = () => <ContainerForMicroFrontend place={WIDGET_PLACE_NAME} />;

export default RenewalServiceWidget;
