import AdditionalCheck from 'lux/components/Employer/AdditionalCheck';
import IndexAnonymous from 'lux/components/IndexPageAnonymous';
import IndexApplicant from 'lux/components/IndexPageApplicant';
import IndexEmployer from 'lux/components/IndexPageEmployer';
import IndexNewEmployer from 'lux/components/IndexPageNewEmployer';
import MobileAppPromotion from 'lux/components/MobileAppPromotion';
import PageLayout, { EmptyLayout } from 'lux/components/PageLayout';
import FullPageLayout from 'lux/components/PageLayout/FullPageLayout';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

const IndexPage = () => {
    const isNewEmployer = useSelector(({ isNewEmployer }) => isNewEmployer);
    const { title } = useSelector((state) => state.pageMetaData);
    const showAdditionalCheck = useSelector((state) => state.additionalCheckFirstView);
    const userType = useSelector((state) => state.userType);

    if (userType === UserType.Applicant) {
        return (
            <FullPageLayout>
                <IndexApplicant />
                <MobileAppPromotion />
            </FullPageLayout>
        );
    }

    if (userType === UserType.Employer) {
        return (
            <PageLayout layout={EmptyLayout} title={title}>
                {showAdditionalCheck && <AdditionalCheck />}
                {isNewEmployer ? <IndexNewEmployer /> : <IndexEmployer />}
            </PageLayout>
        );
    }

    return (
        <FullPageLayout>
            <IndexAnonymous />
        </FullPageLayout>
    );
};

export default IndexPage;
