import { FC, useState, ChangeEvent } from 'react';

import vacancySearchQuickFilterApplyButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_filter/vacancy_search_quick_filter_apply_button_click';
import CheckboxChip from 'bloko/blocks/chips/CheckboxChip';

import { ProfessionalRoleTreeItem } from 'lux/models/professionalRoleTree';

interface SearchProfareaChipsItemProps {
    item: ProfessionalRoleTreeItem;
    index: number;
    onSelect: (categoryId: string, roles: ProfessionalRoleTreeItem[]) => void;
}

const SearchProfareaChipsItem: FC<SearchProfareaChipsItemProps> = ({ item, onSelect, index }) => {
    const [isChildVisible, setIsChildVisible] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState<ProfessionalRoleTreeItem[]>([]);

    const handleSelectCategory = (isChecked: boolean, itemId: string, itemIndex: number) => {
        setIsChildVisible(isChecked);
        if (isChecked) {
            vacancySearchQuickFilterApplyButtonClick({
                hhtmSourceLabel: 'TOP_FILTER_CATALOG',
                hhtmSource: 'main',
                filter: itemId,
                position: itemIndex,
            });
        }
        const rolesToSelect = isChecked ? item.items : [];
        if (rolesToSelect) {
            setSelectedRoles(rolesToSelect);
            onSelect(item.id, rolesToSelect);
        }
    };

    const handleSelectRole = (isChecked: boolean, role: ProfessionalRoleTreeItem, childIndex: number) => {
        let rolesToSelect = [];
        if (isChecked) {
            vacancySearchQuickFilterApplyButtonClick({
                hhtmSourceLabel: 'TOP_FILTER_CATALOG',
                hhtmSource: 'main',
                filter: role.id,
                position: index + childIndex,
            });
            // Если количество выбранных ролей === количеству ролей в категории, то надо выбрать только чекнутую, а остальные исключить
            if (item.items && item.items.length === selectedRoles.length) {
                rolesToSelect = [role];
            } else {
                rolesToSelect = [...selectedRoles, role];
            }
        } else if (selectedRoles.length === 1) {
            rolesToSelect = item.items ?? [];
        } else {
            rolesToSelect = selectedRoles.filter((item: ProfessionalRoleTreeItem) => item.id !== role.id);
        }
        setSelectedRoles(rolesToSelect);
        onSelect(item.id, rolesToSelect);
    };

    return (
        <>
            <CheckboxChip
                key={`${item.text}-${item.id}`}
                name={item.text}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleSelectCategory(event.target.checked, item.id, index + 1)
                }
                checked={isChildVisible}
                value={item.id}
                id={item.id}
            >
                {item.text}
            </CheckboxChip>
            {isChildVisible && (
                <>
                    {!!item.items &&
                        item.items.map((child, childIndex) => (
                            <CheckboxChip
                                key={`${child.text}-${child.id}`}
                                name={child.text}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleSelectRole(event.target.checked, child, childIndex + 1)
                                }
                                value={child.id}
                                id={child.id}
                            >
                                {child.text}
                            </CheckboxChip>
                        ))}
                </>
            )}
        </>
    );
};

export default SearchProfareaChipsItem;
