import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import { H3Promo } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaSearch from 'lux/components/SupernovaSearch';
import useSearchBaseState from 'lux/components/SupernovaSearch/useSearchBaseState';
import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { TopLevelSite } from 'lux/models/topLevelSite';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    header: 'index.headers.main',
    headerRuBy: 'index.headers.main.jobForEveryone',
    lookingForEmployee: 'index.search.lookingForEmployee',
};

const sendLookingForEmployeeClick = () => Analytics.sendHHEventButtonClick('looking_for_employee_index_page');

const DashboardSearch: TranslatedComponent = ({ trls }) => {
    const dashboard = useSelector(({ supernovaAnonymousDashboard }) => supernovaAnonymousDashboard);
    const lookingForEmployeeMenuItem = useSelector(({ headerMenu }) => headerMenu).find(
        (menuItem) => menuItem.name === 'lookingForEmployee'
    );
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);
    const searchBaseState = useSearchBaseState();
    const supernovaSearchState = { ...searchBaseState, isAnonymousDashboardSearch: true };
    const isZp = useIsZarplataPlatform();
    const { h1 } = useSelector((state) => state.pageMetaData);

    return (
        <div
            className={classnames('supernova-dashboard-search', {
                'supernova-dashboard-search_with-big-promo': dashboard.bigPromoEmployerId,
            })}
        >
            {isZp ? (
                <H3Promo Element="h1">{h1 || trls[TrlKeys.header]}</H3Promo>
            ) : (
                <H3Promo>
                    {[TopLevelSite.RU, TopLevelSite.BY].includes(topLevelSite)
                        ? trls[TrlKeys.headerRuBy]
                        : trls[TrlKeys.header]}
                </H3Promo>
            )}
            <>
                <VSpacing base={6} />
                <SupernovaSearch {...supernovaSearchState} />
                {lookingForEmployeeMenuItem && (
                    <>
                        <VSpacing base={6} />
                        <Link
                            to={lookingForEmployeeMenuItem.url}
                            className="supernova-dashboard-link-switch"
                            onClick={sendLookingForEmployeeClick}
                        >
                            <Text size={TextSize.Large} Element="span">
                                {trls[TrlKeys.lookingForEmployee]}
                            </Text>
                        </Link>
                    </>
                )}
            </>
        </div>
    );
};

export default translation(DashboardSearch);
