import { useEffect } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import { UXFeedback } from 'lux/utils/uxfeedback';

export const useSendMagritteResumeSerpUxFb = (): void => {
    const isMagritteResumeSerp = useSelector((state) => state.isMagritteResumeSerp);

    useEffect(() => {
        if (isMagritteResumeSerp) {
            UXFeedback.sendEvent('resume_search_quality_employer_magritte');
        }
    }, [isMagritteResumeSerp]);
};
