import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Text, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import { markUpdateViewed } from 'lux/models/oldEmployer/oldEmployerUpdates';

const ItemLink = ({ date, text, href, viewed = false }) => {
    const dispatch = useDispatch();

    return (
        <div className="updates-item-link">
            <MagritteLink
                Element={Link}
                to={href}
                onClick={() => {
                    dispatch(markUpdateViewed({ href }));
                }}
                style="neutral"
            >
                <div
                    className={classnames('updates-item-link-inner', {
                        'updates-item-link-inner_not-viewed': !viewed,
                    })}
                >
                    <div className="updates-text">
                        <Text typography="label-2-regular">{text}</Text>
                    </div>
                </div>
            </MagritteLink>

            {date && (
                <Text typography="label-3-regular" style="secondary">
                    {date}
                </Text>
            )}
        </div>
    );
};

ItemLink.propTypes = {
    date: PropTypes.string,
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    viewed: PropTypes.bool,
};

export default ItemLink;
