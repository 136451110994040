import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import useToggleState from 'lux/hooks/useToggleState';
import {
    ProfessionalRoleCategory,
    ProfessionalRoleCategoryCounted,
} from 'lux/models/professionalRoleCategoryCollection';

import Header from 'lux/components/WorkInProfession/Header';
import ListItem from 'lux/components/WorkInProfession/ListItemMobile';

import styles from './modal-group-mobile.less';

const TrlKeys = {
    showAll: 'industriesCatalog.viewAll',
};

interface MobileModalProps {
    title: string;
    collection: ProfessionalRoleCategory[] | ProfessionalRoleCategoryCounted[];
}

const ModalGroupMobile: TranslatedComponent<MobileModalProps> = ({ title, collection, trls }) => {
    const [showModal, toggleShowModal] = useToggleState(false);

    return (
        <>
            <BlokoLink
                appearance={LinkAppearance.Pseudo}
                onClick={toggleShowModal}
                data-page-analytics-event="vacancy_professional_catalog_list.Mobile"
                data-qa="industries-catalog-trigger"
            >
                {trls[TrlKeys.showAll]}
            </BlokoLink>
            <Modal visible={showModal} onClose={toggleShowModal}>
                <ModalHeader outlined>
                    <ModalTitle>
                        <Header text={title} />
                        <VSpacing base={4} />
                    </ModalTitle>
                </ModalHeader>
                <div className={styles.modal}>
                    <ul className={styles.modalContent} data-qa="index__work-in-profession-modal-list">
                        {collection.map((category, index) => (
                            <ListItem
                                key={category.id}
                                category={category}
                                positionIndex={index}
                                isLast={index === collection.length - 1}
                                isModal
                            />
                        ))}
                    </ul>
                </div>
            </Modal>
        </>
    );
};

export default translation(ModalGroupMobile);
