import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import { getProfessionLink } from 'lux/components/RainbowCatalog/utils';
import translation from 'lux/components/translation';
import { ProfessionsListProfession } from 'lux/models/professionsList';

interface RainbowDropProfessionProps {
    professions: ProfessionsListProfession[];
    parentProfession: ProfessionsListProfession;
}

const RainbowDropProfession: FC<RainbowDropProfessionProps> = ({ professions, parentProfession }) => {
    return (
        <ul className="multiple-column-list multiple-column-list_wide">
            {[parentProfession, ...professions].map((profession, index) => {
                return (
                    <li className="multiple-column-list-item" key={profession.id}>
                        <Text>
                            <BlokoLink
                                Element={Link}
                                to={getProfessionLink(parentProfession, profession)}
                                additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                                kind={LinkKind.Tertiary}
                                data-page-analytics-value={profession.name}
                                data-page-analytics-position={index}
                                data-page-analytics-event="vacancy_rainbow_catalog_item_link.rainbowLinkMobile"
                            >
                                <div className="easy-tap-link easy-tap-link_with-arrow">
                                    <div>{profession.name}</div>
                                    <div className="easy-tap-link-number">{profession.count}</div>
                                </div>
                            </BlokoLink>
                        </Text>
                    </li>
                );
            })}
        </ul>
    );
};

export default translation(RainbowDropProfession);
