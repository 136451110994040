import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Cell, CellText, Link as MagritteLink, Tag, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import NumberFormatter from 'bloko/common/numberFormatter';

import useMagritte from 'lux/hooks/useMagritte';
import { openAnonymousSignUpModal } from 'lux/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'lux/models/anonymousSignUp/hooks';
import {
    ProfessionalRoleCategory,
    ProfessionalRoleCategoryCounted,
} from 'lux/models/professionalRoleCategoryCollection';
import { WITH_TOP_FILTER_CATALOG } from 'lux/models/topFilterCatalog';

import { sendAnalytics } from 'lux/components/WorkInProfession/Utils';

export interface ListItemProps {
    category: ProfessionalRoleCategory | ProfessionalRoleCategoryCounted;
    positionIndex: number;
}

const ListItem: FC<ListItemProps> = ({ category, positionIndex }) => {
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const dispatch = useDispatch();
    const ref = useRef<HTMLAnchorElement>(null);
    const isMagritte = useMagritte();
    const { isGtS } = useBreakpoint();

    if (isMagritte) {
        const needToRenderTag = !isGtS && 'count' in category;
        return (
            <>
                <Cell right={needToRenderTag ? <Tag>{NumberFormatter.format(String(category.count))}</Tag> : undefined}>
                    <CellText>
                        <MagritteLink
                            style="neutral"
                            Element={Link}
                            to={category.url}
                            additionalQueryParams={{ [WITH_TOP_FILTER_CATALOG]: true }}
                            isSeoLink
                            ref={ref}
                            onClick={(event) => {
                                if (mainPageSignUpEnable) {
                                    event.preventDefault();
                                    dispatch(openAnonymousSignUpModal(ref.current?.href ?? ''));
                                }
                                sendAnalytics(category.id, positionIndex);
                            }}
                        >
                            {category.name}
                        </MagritteLink>
                    </CellText>
                </Cell>
            </>
        );
    }

    return (
        <li className="multiple-column-list-item">
            <Text Element="span">
                <BlokoLink
                    kind={LinkKind.Tertiary}
                    Element={Link}
                    to={category.url}
                    additionalQueryParams={{ [WITH_TOP_FILTER_CATALOG]: true }}
                    isSeoLink
                    ref={ref}
                    onClick={(event) => {
                        if (mainPageSignUpEnable) {
                            event.preventDefault();
                            dispatch(openAnonymousSignUpModal(ref.current?.href ?? ''));
                        }
                        sendAnalytics(category.id, positionIndex);
                    }}
                >
                    {category.name}
                </BlokoLink>
            </Text>
        </li>
    );
};

export default ListItem;
