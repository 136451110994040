import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'lux/components/translation';

const TrlKeys = {
    textNew: 'applicant.settings.phone.new.success',
};

const AddAuthPhoneSuccess: TranslatedComponent<{ phoneNumber: string }> = ({ trls, phoneNumber }) => (
    <>
        {format(trls[TrlKeys.textNew], {
            '{formattedPhone}': phoneNumber,
        })}
    </>
);

export default {
    Element: translation(AddAuthPhoneSuccess),
    kind: 'ok',
    autoClose: true,
    autoCloseDelay: 30000,
};
