import { FC } from 'react';

import EmployerReviewsWidget from 'lux/components/EmployerReviews/WidgetOnMain';
import RenewalServiceWidget, { useRenewalServiceHasContent } from 'lux/components/RenewalService/RenewalServiceWidget';
import { useIsRussia } from 'lux/hooks/useCountries';

const AdditionalServices: FC = () => {
    const renewalServiceHasContent = useRenewalServiceHasContent();
    const isRussia = useIsRussia();

    if (!isRussia) {
        return null;
    }
    return renewalServiceHasContent ? <RenewalServiceWidget /> : <EmployerReviewsWidget />;
};

export default AdditionalServices;
