import { FC, PropsWithChildren, useCallback, useEffect, useState, useMemo, ReactNode } from 'react';

import { Card, useBreakpoint } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AppBanner from 'lux/components/AppBanner';
import AdsExternalBanner from 'lux/components/Banner/AdsExternalBanner';
import ContainerForXSL from 'lux/components/CodeInjector/ContainerForXSL';
import InternalPromoBlock from 'lux/components/InternalPromoBlock';
import NewsBox from 'lux/components/NewsBox';
import NovaFilters from 'lux/components/NovaFilters';
import RainbowCatalog from 'lux/components/RainbowCatalog';
import useFetchVacanciesOfTheDay from 'lux/components/RainbowCatalog/useFetchVacanciesOfTheDay';
import RecommendedVacanciesMobile from 'lux/components/RecommendedVacancies/Mobile';
import MainPageSignUpModal from 'lux/components/SignupModal/MainPageSignUpModal';
import StayInTouch from 'lux/components/StayInTouch';
import UsefulLinks from 'lux/components/UsefulLinks';
import VacanciesOfTheDay from 'lux/components/VacanciesOfTheDay';
import VacanciesOfTheDayBanners from 'lux/components/VacanciesOfTheDay/Banners';
import VacanciesOfTheDayMobile from 'lux/components/VacanciesOfTheDay/Mobile';
import { MIN_VACANCIES_NUM_TO_SHOW } from 'lux/components/VacanciesOfTheDay/constants';
import novaFiltersOrder from 'lux/components/VacancySearchContent/components/novaFiltersOrder';
import WorkInCompany from 'lux/components/WorkInCompany';
import WorkInCompanyMobile from 'lux/components/WorkInCompany/Mobile';
import WorkInOtherCities from 'lux/components/WorkInOtherCities';
import WorkInProfession from 'lux/components/WorkInProfession';
import WorkInProfessionMobile from 'lux/components/WorkInProfession/Mobile';
import translation from 'lux/components/translation';
import { useAddEventPixelsRu } from 'lux/hooks/useAddEventPixelsRu';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useIsRabotaBy } from 'lux/hooks/useSites';
import { BannerObject, BannerPlace } from 'lux/models/banners';
import { ProfessionalRoleTreeItem } from 'lux/models/professionalRoleTree';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import CreateResumeButton from 'lux/components/IndexPageAnonymous/CreateResumeButton';
import Dashboard from 'lux/components/IndexPageAnonymous/Dashboard';
import DashboardMobileSearch from 'lux/components/IndexPageAnonymous/DashboardMobileSearch';
import SearchProfareaChips from 'lux/components/IndexPageAnonymous/SearchProfareaChips';
import SearchResultIndexAnonymous from 'lux/components/IndexPageAnonymous/SearchResultIndexAnonymous';
import SignUpBanner from 'lux/components/IndexPageAnonymous/SignUp/Banner';
import SignUpFormCompact from 'lux/components/IndexPageAnonymous/SignUpFormCompact';

const TrlKeys = {
    newsTitle: 'index.newsTitle',
    articlesTitle: 'index.Articles',
    usefulTitle: 'index.headers.useful',
    filterTitle: 'vacancySearch.filterTitle',
    feedTitle: {
        all: 'index.anonymous.vacancies.title',
        withFilter: 'index.anonymous.vacancies.title.with.filter',
    },
    headerPhone: 'signup.header.phone',
    signup: {
        titleA: 'index.anonymous.signup.title.a',
        titleB: 'index.anonymous.signup.title.b',
        subtitle: 'index.anonymous.signup.subtitle',
    },
};

const IndexAnonymous: TranslatedComponent<FC<PropsWithChildren>> = ({ trls }) => {
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);
    const stayInTouch = useSelector((state) => state.stayInTouch);
    const news = useSelector((state) => state.applicantNews);
    const articles = useSelector((state) => state.applicantArticles);
    const useful = useSelector((state) => state.applicantUseful);
    const bannersDashboardMobile = useSelector((state) => state.banners[BannerPlace.INDEX_DASHBOARD_MOBILE]);
    const bannersUnderRainbowMobile = useSelector((state) => state.banners[BannerPlace.INDEX_UNDER_RAINBOW_MOBILE]);
    const bannersHorizontal = useSelector((state) => state.banners[BannerPlace.INDEX_HORIZONTAL]);
    const bannersUnderNewsBoxMobile = useSelector((state) => state.banners[BannerPlace.INDEX_UNDER_NEWS_BOX_MOBILE]);
    const bannersUnderCreateResumeButton = useSelector((state) => state.banners[BannerPlace.INDEX_BOTTOM_MOBILE]);
    const showWicAndVod = vacanciesOfTheDay.success && vacanciesOfTheDay.vacancies.length > MIN_VACANCIES_NUM_TO_SHOW;
    const chatBot = useSelector((state) => state.chatBot);
    const professionalRoleCategoryCollection = useSelector((state) => state.professionalRoleCategoryCollection);
    const addEventPixelsRu = useAddEventPixelsRu();
    const isZp = useIsZarplataPlatform();
    const isAnonymousSearchResultExp = useSelector((state) => state.isAnonymousSearchResultExp);
    const isIndexAnonSearchRedirectDisabled = useSelector((state) => state.isIndexAnonSearchRedirectDisabled);
    const isAnonymousSearchProfroleFilterExp = useSelector((state) => state.isAnonymousSearchProfroleFilterExp);
    const isAnonymousSignupTiteExp = useSelector((state) => state.isAnonymousSignupTiteExp);
    const { isMobile } = useBreakpoint();

    const shouldShowMobileFilter = useSelector(({ searchPreference }) => searchPreference.isShown);
    const isMobileFilterVisible = shouldShowMobileFilter && isMobile;
    const [selectedRoles, setSelectedRoles] = useState<Record<string, ProfessionalRoleTreeItem[]>>({});

    const vacancySearchResult = useSelector((state) => state.vacancySearchResult);
    const criteria = vacancySearchResult?.criteria;
    const [signUpHeaderContent, setSignUpHeaderContent] = useState<ReactNode>();
    useFetchVacanciesOfTheDay();

    const shouldShowProfroleChips = isAnonymousSearchProfroleFilterExp || isAnonymousSignupTiteExp;

    const isRabotaBy = useIsRabotaBy();
    const siteNewsHref = isRabotaBy ? '/articles/applicants' : '/articles/site-news';

    useEffect(() => {
        addEventPixelsRu('B2C_MAIN_PAGE_SHOWN');
    }, [addEventPixelsRu]);

    const setHeader = useCallback((content: ReactNode) => {
        setSignUpHeaderContent(content);
    }, []);

    // tempexp_PORTFOLIO-30605_start
    const renderFilters = () => {
        if (!criteria) {
            return null;
        }
        if (isMobile) {
            return (
                <NovaFilters
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    criteria={criteria}
                    filtersOrder={novaFiltersOrder}
                    withCounter
                    withSorting={false}
                    isIndexAnonSearchRedirectDisabled={isIndexAnonSearchRedirectDisabled}
                    additionalFiltersOrder={[]}
                />
            );
        }
        return (
            <Card padding={16} borderRadius={16} showBorder>
                <NovaFilters
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    criteria={criteria}
                    filtersOrder={novaFiltersOrder}
                    withCounter
                    withSorting={false}
                    isIndexAnonSearchRedirectDisabled={isIndexAnonSearchRedirectDisabled}
                    additionalFiltersOrder={[]}
                />
            </Card>
        );
    };

    const renderSignInForm = () => (
        <>
            <Card padding={16} borderRadius={16} showBorder>
                {signUpHeaderContent}
                <VSpacing base={4} />
                <SignUpFormCompact setHeader={setHeader} />
            </Card>
            <VSpacing base={4} />
        </>
    );

    const handleSelectRoles = (roles: Record<string, ProfessionalRoleTreeItem[]>) => {
        setSelectedRoles(roles);
    };

    const searchFeedTitle = useMemo(() => {
        const categories = Object.keys(selectedRoles);
        return categories.length ? trls[TrlKeys.feedTitle.withFilter] : trls[TrlKeys.feedTitle.all];
    }, [trls, selectedRoles]);
    // tempexp_PORTFOLIO-30605_end

    return (
        <>
            <Dashboard />
            <DashboardMobileSearch />
            {!shouldShowProfroleChips && <SignUpBanner />}
            {/* tempexp_PORTFOLIO-30605_start */}
            {isAnonymousSearchResultExp && (
                <>
                    {!isMobileFilterVisible ? (
                        <>
                            <VSpacing base={8} xs={6} />
                            <ColumnsWrapper>
                                <Column xs="0" s="0" m="4" l="4">
                                    {shouldShowProfroleChips && renderSignInForm()}
                                    {renderFilters()}
                                </Column>
                                <Column xs="4" s="8" m="8" l="12">
                                    {shouldShowProfroleChips && (
                                        <>
                                            {isMobile && renderSignInForm()}
                                            <SearchProfareaChips onSelectRole={(roles) => handleSelectRoles(roles)} />
                                            <VSpacing base={6} />
                                        </>
                                    )}
                                    <SearchResultIndexAnonymous title={searchFeedTitle} />
                                </Column>
                            </ColumnsWrapper>
                        </>
                    ) : (
                        <ColumnsWrapper>
                            <VSpacing base={8} xs={6} />
                            <Column xs="4" s="8" m="0" l="0">
                                {renderFilters()}
                            </Column>
                        </ColumnsWrapper>
                    )}
                </>
            )}
            {/* tempexp_PORTFOLIO-30605_end */}
            <VacanciesOfTheDayMobile />
            <Column xs="4" s="0" m="0" l="0" container>
                <Gap top>
                    {bannersDashboardMobile?.map((banner: BannerObject) => (
                        <Gap bottom key={banner.id}>
                            <div data-page-analytics-event="advertising_banner.XsBeforeCompanies">
                                <AdsExternalBanner {...banner} />
                            </div>
                        </Gap>
                    ))}
                    <WorkInCompanyMobile />
                </Gap>
            </Column>
            <RainbowCatalog />
            <RecommendedVacanciesMobile />
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16" container>
                    {showWicAndVod && (
                        <Column xs="0" s="8" m="12" l="16" container>
                            <Column xs="0" s="4" m="8" l="12" container>
                                <Column xs="0" s="4" m="8" l="4" container>
                                    <Gap top>
                                        <WorkInCompany />
                                    </Gap>
                                </Column>
                                <Column xs="0" s="8" m="8" l="8">
                                    <VacanciesOfTheDay userType={UserType.Anonymous} />
                                </Column>
                            </Column>
                            <Column xs="0" s="4" m="4" l="4" container>
                                <VacanciesOfTheDayBanners />
                            </Column>
                        </Column>
                    )}
                    {bannersUnderRainbowMobile?.map((banner: BannerObject) => (
                        <Column xs="4" s="0" m="0" l="0" container key={banner.id}>
                            <div
                                className="index-section-app-banner"
                                data-page-analytics-event="advertising_banner.XSAfterRainbow"
                            >
                                <AdsExternalBanner {...banner} />
                            </div>
                        </Column>
                    ))}
                    <AppBanner />
                    {professionalRoleCategoryCollection && (
                        <Column xs="4" s="0" m="0" l="0">
                            <VSpacing base={6} />
                            <WorkInProfessionMobile />
                        </Column>
                    )}
                    <Column xs="0" s="8" m="12" l="16" container>
                        {!bannersHorizontal?.length && (
                            <Column xs="0" s="8" m="12" l="16">
                                <div className="separator" />
                            </Column>
                        )}
                        {bannersHorizontal?.map((banner: BannerObject) => (
                            <Column xs="0" s="8" m="12" l="16" key={banner.id}>
                                <Gap top>
                                    <AdsExternalBanner {...banner} />
                                </Gap>
                            </Column>
                        ))}
                    </Column>
                </Column>
                {chatBot && (
                    <>
                        <Column xs="4" s="0" m="0" l="0">
                            <div className="separator" />
                            <Gap top>
                                <InternalPromoBlock isInline />
                            </Gap>
                            <div className="separator" />
                        </Column>
                    </>
                )}
                {!chatBot && stayInTouch.apps.length > 0 && (
                    <Column xs="0" s="0" m="0" l="0" container>
                        <Gap top>
                            <StayInTouch isInline analyticsFrom="sms_main" />
                        </Gap>
                    </Column>
                )}
                <Column xs="4" s="8" m="12" l="16" container>
                    <Gap top>
                        <div>
                            {professionalRoleCategoryCollection && (
                                <Column xs="0" s="8" m="12" l="12">
                                    <WorkInProfession />
                                </Column>
                            )}
                            {chatBot && (
                                <>
                                    <Column xs="0" s="8" m="12" l="0">
                                        <div className="separator" />
                                        <Gap top>
                                            <InternalPromoBlock isInline />
                                        </Gap>
                                    </Column>
                                    <Column xs="0" s="0" m="0" l="4">
                                        <InternalPromoBlock />
                                    </Column>
                                </>
                            )}
                            {!chatBot && stayInTouch.apps.length > 0 && (
                                <>
                                    <Column xs="0" s="8" m="12" l="0" container>
                                        <Gap top>
                                            <StayInTouch isInline analyticsFrom="sms_main" />
                                        </Gap>
                                    </Column>
                                    <Column xs="0" s="0" m="0" l="4" container>
                                        <StayInTouch analyticsFrom="sms_main" />
                                    </Column>
                                </>
                            )}
                            <Column xs="0" s="8" m="12" l="16">
                                <div className="separator" />
                            </Column>
                            <Column xs="4" s="0" m="0" l="0" container>
                                <NewsBox
                                    items={news}
                                    title={trls[TrlKeys.newsTitle]}
                                    href={siteNewsHref}
                                    isEmployerContext={false}
                                />
                            </Column>
                            <Column xs="0" s="4" m="4" l={isZp ? '5' : '4'} container>
                                <NewsBox
                                    items={news}
                                    title={trls[TrlKeys.newsTitle]}
                                    href={siteNewsHref}
                                    isEmployerContext={false}
                                    gapTop
                                />
                            </Column>
                            <Column xs="4" s="0" m="0" l="0">
                                {bannersUnderNewsBoxMobile?.map((banner: BannerObject) => (
                                    <Gap top bottom key={banner.id}>
                                        <div data-page-analytics-event="advertising_banner.XSAfterNews">
                                            <AdsExternalBanner {...banner} />
                                        </div>
                                    </Gap>
                                ))}
                            </Column>
                            <Column xs="4" s="0" m="0" l="0" container>
                                <NewsBox
                                    items={articles}
                                    title={trls[TrlKeys.articlesTitle]}
                                    href="/articles"
                                    isArticle
                                    isEmployerContext={false}
                                    gapTop
                                />
                            </Column>
                            <Column xs="0" s="4" m="4" l={isZp ? '5' : '4'} container>
                                <NewsBox
                                    items={articles}
                                    title={trls[TrlKeys.articlesTitle]}
                                    href="/articles"
                                    isArticle
                                    isEmployerContext={false}
                                    gapTop
                                />
                            </Column>
                            <Column xs="0" s="8" m="0" l="0">
                                <div className="separator" />
                            </Column>
                            <Column xs="0" s="4" m="4" l={isZp ? '6' : '4'}>
                                <UsefulLinks title={trls[TrlKeys.usefulTitle]} items={useful} />
                            </Column>
                            {!isZp && (
                                <>
                                    <Column xs="0" s="0" m="12" l="0">
                                        <div className="separator" />
                                    </Column>
                                    <Column xs="4" s="4" m="4" l="4">
                                        <Gap top>
                                            <WorkInOtherCities />
                                        </Gap>
                                    </Column>
                                </>
                            )}
                        </div>
                    </Gap>
                </Column>
                <Column xs="0" s="8" m="12" l="16">
                    <div className="separator" />
                </Column>
                <ContainerForXSL place="work-in-city-xsl" styleClass="work-in-city-wrapper" />
                <CreateResumeButton />
                <Column xs="4" s="0" m="0" l="0">
                    {bannersUnderCreateResumeButton?.map((banner: BannerObject) => (
                        <Gap bottom key={banner.id}>
                            <div data-page-analytics-event="advertising_banner.XSBottom">
                                <AdsExternalBanner {...banner} />
                            </div>
                        </Gap>
                    ))}
                </Column>
            </ColumnsWrapper>
            <MainPageSignUpModal />
        </>
    );
};

export default translation(IndexAnonymous);
