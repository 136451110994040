import { useRef } from 'react';
import PropTypes from 'prop-types';

import { Link, Tooltip, BottomSheet, useBreakpoint } from '@hh.ru/magritte-ui';

import useOnOffState from 'lux/hooks/useOnOffState';

const LinkInfo = ({ children, tooltipContent, onClick = () => {}, ...linkSwitchProps }) => {
    const activatorRef = useRef(null);
    const [visible, showTip, hideTip] = useOnOffState(false);
    const { isMobile } = useBreakpoint();

    const handleClick = () => {
        showTip();
        onClick();
    };

    return (
        <>
            <Link
                ref={activatorRef}
                inline
                typography="label-2-regular"
                style="accent"
                onClick={handleClick}
                {...linkSwitchProps}
            >
                {children}
            </Link>
            {isMobile ? (
                <BottomSheet visible={visible} onClose={hideTip}>
                    {tooltipContent}
                </BottomSheet>
            ) : (
                <Tooltip
                    activatorRef={activatorRef}
                    visible={visible}
                    closeByClickOutside
                    onClose={hideTip}
                    placement="top-center"
                >
                    {tooltipContent}
                </Tooltip>
            )}
        </>
    );
};

LinkInfo.propTypes = {
    children: PropTypes.node,
    tooltipContent: PropTypes.node,
    onClick: PropTypes.func,
};

export default LinkInfo;
