import { useEffect, ReactNode, useMemo } from 'react';

import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import VerificationForm, { VerificationFormBaseProps } from 'lux/components/Verification/VerificationForm';
import translation from 'lux/components/translation';

import styles from './styles.less';

const TrlKeys = {
    header: 'signUpForm.verification.header',
    phone: {
        sent: 'signUpForm.verification.codeSent.phone',
        description1: 'signUpForm.verification.codeSentDescription.1.phone',
        description2: 'signUpForm.verification.codeSentDescription.2.phone',
    },
    email: {
        sent: 'signUpForm.verification.codeSent.email',
        description: 'otp.confirm.sub.header',
    },
};

export interface VerificationStepProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
    setHeader: (headerContent: ReactNode) => void;
}

const VerificationStep: TranslatedComponent<VerificationStepProps> = ({
    trls,
    setHeader,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    ...formProps
}) => {
    const { authType, login } = formProps;

    const headerContent = useMemo(() => {
        return (
            <>
                <Text size={TextSize.Large} strong>
                    {trls[TrlKeys.header]}
                </Text>
                <VSpacing base={3} />
                <p>
                    {formatToReactComponent(trls[authType === 'EMAIL' ? TrlKeys.email.sent : TrlKeys.phone.sent], {
                        '{0}': <strong>{login}</strong>,
                    })}
                </p>
                <VSpacing base={3} />
                {authType === 'EMAIL' ? (
                    <p>{trls[TrlKeys.email.description]}</p>
                ) : (
                    <>
                        <p>{trls[TrlKeys.phone.description1]}</p>
                        <p>{trls[TrlKeys.phone.description2]}</p>
                    </>
                )}
            </>
        );
    }, [authType, login, trls]);

    useEffect(() => {
        setHeader(headerContent);
    }, [headerContent, setHeader]);

    return (
        <VerificationForm
            render={({ renderInput, renderCodeError, renderCodeSender, renderSubmit }) => {
                return (
                    <>
                        <div className={styles.container}>
                            {renderInput({
                                placeholder: codePlaceholder,
                                isAutofocus: true,
                            })}
                            {renderSubmit({ isStretched: true })}
                        </div>
                        <div>
                            {renderCodeError()}
                            <VSpacing base={3} />
                            <Text importance={TextImportance.Tertiary}>{renderCodeSender()}</Text>
                        </div>
                    </>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(VerificationStep);
