import { useEffect, useRef, useState } from 'react';

import resumeCreationCardShown from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-26342/resume_creation_card_shown';
import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ImportResumeModal from 'lux/components/ImportResumeModal';
import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

import Card, { CardType } from 'lux/components/IndexPageApplicant/Actions/Card';

const TrlKeys = {
    title: 'index.applicant.actions.createResume.title',
    link: 'index.applicant.actions.createResume.link',
    description: 'index.applicant.actions.createResume.description',
};

const CreateResume: TranslatedComponent = ({ trls }) => {
    const resumesForUpdate = useSelector((state) => state.resumesForUpdate);
    const canUpdate = (resumesForUpdate?.length || 0) > 0;
    const createResumeRef = useRef(null);
    const isZP = useIsZarplataPlatform();
    const [importZpVisible, setImportZpVisible] = useState(false);

    useEffect(() => {
        if (createResumeRef.current) {
            resumeCreationCardShown(createResumeRef.current);
        }
    }, []);

    if (canUpdate) {
        return null;
    }

    const handleClick = () => {
        buttonClick({ buttonName: 'create_resume_card' });
        if (isZP) {
            setImportZpVisible(true);
        }
    };

    return (
        <>
            <Card
                dataQa="applicant-index-nba-action_create-resume"
                caption={trls[TrlKeys.title]}
                description={trls[TrlKeys.description]}
                linkText={trls[TrlKeys.link]}
                type={CardType.AddResume}
                linkTo={isZP ? undefined : '/applicant/resumes/new'}
                cardRef={createResumeRef}
                onClick={handleClick}
            />
            <ImportResumeModal
                visible={importZpVisible}
                onClose={() => setImportZpVisible(false)}
                url={'/applicant/resumes/new'}
                activatorRef={createResumeRef}
            />
        </>
    );
};

export default translation(CreateResume);
