// tempexp_clickme-vacancy-of-the-day-experiment_file
import { MAX_VOD_VACANCIES_COUNT } from 'lux/components/RainbowCatalog/const';
import type { Vacancies } from 'lux/components/RainbowCatalog/types';

declare global {
    interface FetcherGetApi {
        '/shards/areas_translate': {
            queryParams: {
                areas: number[];
            };
            response: Record<string, string>;
        };
    }
}

function isRealVacancyOfTheDay(vacancyOfTheDayId: number | null = null) {
    return vacancyOfTheDayId !== null;
}

export function calcFirstFakeVacancyIndex(vacancyOfTheDay: Vacancies): number {
    let firstFakeVacancyIndex = MAX_VOD_VACANCIES_COUNT;

    vacancyOfTheDay.some(({ vacancyOfTheDayId }, i) => {
        if (!isRealVacancyOfTheDay(vacancyOfTheDayId)) {
            firstFakeVacancyIndex = Math.min(i, MAX_VOD_VACANCIES_COUNT);
            return true;
        }

        return false;
    });

    return firstFakeVacancyIndex;
}

function promoteClickmeVacancyToFirstPlace(resultVacancies: Vacancies, firstFakeVacancyIndex: number) {
    resultVacancies.unshift(resultVacancies[firstFakeVacancyIndex]);
    resultVacancies.splice(firstFakeVacancyIndex + 1, 1);
}

function getResultVacancies(
    vacancyOfTheDay: Vacancies,
    clickmeVacancies: Vacancies,
    firstFakeVacancyIndex: number,
    isReplaceClickmeVacancyExp: boolean
) {
    if (!clickmeVacancies.length) {
        return vacancyOfTheDay;
    }

    const fakeVacancies = vacancyOfTheDay.slice(firstFakeVacancyIndex, MAX_VOD_VACANCIES_COUNT);
    const clickmeVacanciesToMerge = clickmeVacancies.slice(0, MAX_VOD_VACANCIES_COUNT - firstFakeVacancyIndex);
    const result = vacancyOfTheDay.slice(0, firstFakeVacancyIndex).concat(clickmeVacanciesToMerge);
    const newOldVacanciesDifference = MAX_VOD_VACANCIES_COUNT - result.length;

    if (isReplaceClickmeVacancyExp && clickmeVacanciesToMerge.length) {
        promoteClickmeVacancyToFirstPlace(result, firstFakeVacancyIndex);
    }

    if (newOldVacanciesDifference > 0 && fakeVacancies.length > 0) {
        return result.concat(fakeVacancies.slice(0, newOldVacanciesDifference));
    }

    return result;
}

export default function shuffleVacanciesOfTheDayWithClickme(
    vacanciesOfTheDay: Vacancies,
    clickmeVacancies: Vacancies,
    firstFakeVacancyIndex: number,
    isReplaceClickmeVacancyExp: boolean
): Vacancies {
    return getResultVacancies(vacanciesOfTheDay, clickmeVacancies, firstFakeVacancyIndex, isReplaceClickmeVacancyExp);
}
