import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import InfoInline from 'bloko/blocks/drop/InfoInline';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    info: 'index.recommended.similarSavedSearch.info',
    link: 'index.recommended.similarSavedSearch.link',
    message: {
        one: 'index.recommended.similarSavedSearch.findMessage.one',
        some: 'index.recommended.similarSavedSearch.findMessage.some',
    },
    vacancy: {
        zero: 'index.recommended.similarSavedSearch.vacancy.zero',
        one: 'index.recommended.similarSavedSearch.vacancy.one',
        some: 'index.recommended.similarSavedSearch.vacancy.some',
        many: 'index.recommended.similarSavedSearch.vacancy.many',
    },
};

const SimilarSavedSearchInfo: TranslatedComponent = ({ trls }) => {
    const suitableVacancies = useSelector((state) => state.suitableVacancies);
    const applicantLatestResume = useSelector((state) => state.applicantLatestResume);

    const resultsFound = suitableVacancies?.resultsFound || 0;
    const latestResumeHash = applicantLatestResume?.resumeHash || '';

    return (
        <InfoInline
            show
            showCloseButton={false}
            render={() => (
                <>
                    <div className="similar-saved-search-body">
                        {formatToReactComponent(trls[TrlKeys.info], {
                            '{0}': (
                                <Conversion
                                    hasValue={false}
                                    value={resultsFound}
                                    zero={trls[TrlKeys.message.some]}
                                    one={trls[TrlKeys.message.one]}
                                    some={trls[TrlKeys.message.some]}
                                    many={trls[TrlKeys.message.some]}
                                />
                            ),
                            '{1}': resultsFound,
                            '{2}': (
                                <Conversion
                                    hasValue={false}
                                    value={resultsFound}
                                    zero={trls[TrlKeys.vacancy.zero]}
                                    one={trls[TrlKeys.vacancy.one]}
                                    some={trls[TrlKeys.vacancy.some]}
                                    many={trls[TrlKeys.vacancy.many]}
                                />
                            ),
                        })}
                    </div>
                    <div className="similar-saved-search-link">
                        <BlokoLink
                            Element={Link}
                            disableVisited
                            to={`${paths.vacancySearch}?resume=${latestResumeHash}&from=indexPage&forceFiltersSaving=true`}
                            additionalQueryParams={{ hhtmFromLabel: 'rec_vacancy_reset' }}
                            rel="nofollow"
                        >
                            {trls[TrlKeys.link]}
                        </BlokoLink>
                    </div>
                </>
            )}
        />
    );
};

export default translation(SimilarSavedSearchInfo);
