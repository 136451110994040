import { FC, PropsWithChildren } from 'react';

import { GridColumn } from '@hh.ru/magritte-ui';

interface BannerWrapperProps {
    id: string;
}

const BannerWrapper: FC<BannerWrapperProps & PropsWithChildren> = ({ children, id }) => {
    switch (id) {
        case '371':
            return (
                <GridColumn xs={0} s={8} m={4} l={4} flexibleContent>
                    <div className="index-activity-calendar-banner">
                        <div className="banner-place-fat-wrapper banner-place-fat-wrapper_rounded">
                            <div className="index-banner index-banner_big">{children}</div>
                        </div>
                    </div>
                </GridColumn>
            );

        case '370':
            return (
                <GridColumn xs={0} s={8} m={0} l={0} flexibleContent>
                    <div className="banner-place-fat-wrapper banner-place-fat-wrapper_rounded">{children}</div>
                </GridColumn>
            );
    }

    return null;
};

export default BannerWrapper;
