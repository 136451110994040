import { useEffect, useRef } from 'react';
import classnames from 'classnames';

import responseCardShown from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-26342/response_card_shown';
import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';
import { Title, Text } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import LoopCounterParser, { LoopCounterStage } from 'Modules/LoopCounterParser';
import translation from 'lux/components/translation';
import { ApplicantResponseStreak } from 'lux/models/applicantResponseStreaks';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';

import Card from 'lux/components/IndexPageApplicant/Actions/Card';
import CircleProgress from 'lux/components/IndexPageApplicant/Actions/CircleProgress';

import styles from './response-streak.less';

const SUCCESS_BADGES_TOTAL = 7;

const TrlKeys = {
    link: {
        success: 'index.applicant.actions.responseStreak.success.link',
        progress: 'index.applicant.actions.responseStreak.progress.link',
    },
    title: {
        success: 'index.applicant.actions.responseStreak.success.title',
        progress: 'index.applicant.actions.responseStreak.progress.title.redesign',
        progressStart: 'index.applicant.actions.responseStreak.progressStart.title',
    },
    count: {
        one: 'index.applicant.actions.responseStreak.progress.title.count.one',
        some: 'index.applicant.actions.responseStreak.progress.title.count.some',
        many: 'index.applicant.actions.responseStreak.progress.title.count.many',
    },
    vacancyAccusative: {
        one: 'vacancies.accusative.one',
        some: 'vacancies.accusative.some',
        many: 'vacancies.accusative.many',
    },
};

const ResponseStreak: TranslatedComponent = ({ trls }) => {
    const responseStreaks = useSelector((state) => state.applicantResponseStreaks);
    const responseStreaksOverall: ApplicantResponseStreak = responseStreaks.overall || {};
    const { responsesCount, responsesRequired } = responseStreaksOverall;
    const recommendedVacancies = useSelector((state) => state.recommendedVacancies);
    const responseStreakCardRef = useRef(null);

    useEffect(() => {
        if (responseStreakCardRef.current) {
            responseCardShown(responseStreakCardRef.current, { responseCount: responsesCount });
        }
    }, [responsesCount]);

    if (typeof responsesCount !== 'number' && typeof responsesRequired !== 'number') {
        return null;
    }

    const isProgressStart = responsesCount === 0;
    const queries = recommendedVacancies?.queries;
    const fullSearchLink = `${paths.vacancySearch}?${queries ? queries.itemsOnPageBase : ''}`;

    const { roundCounter, completeStepCounter, stage } = LoopCounterParser(responsesRequired, responsesCount);
    const responsesLeft = responsesRequired - completeStepCounter;
    const progress = responsesLeft / responsesRequired;
    const badgeNumber = Math.min(SUCCESS_BADGES_TOTAL, roundCounter);

    const renderIcon = () => {
        if (stage === LoopCounterStage.Success) {
            return (
                <div className={styles.responseStreakSuccessWrapper}>
                    <div className={styles.responseStreakSuccess}>
                        <div
                            className={classnames(
                                styles.responseStreakSuccessBadge,
                                styles[`responseStreakSuccessBadge${badgeNumber}`]
                            )}
                        ></div>
                        <Text typography="label-3-regular" style="accent">
                            {roundCounter}
                        </Text>
                    </div>
                </div>
            );
        }

        return (
            <CircleProgress progress={progress}>
                <Title Element="h3" size="medium">
                    <span className={styles.responseStreakNumber}>{responsesLeft}</span>
                </Title>
            </CircleProgress>
        );
    };

    return (
        <Card
            cardRef={responseStreakCardRef}
            dataQa="applicant-index-nba-action_response-streak"
            caption={
                stage === LoopCounterStage.Success
                    ? trls[TrlKeys.title.success]
                    : formatToReactComponent(
                          isProgressStart ? trls[TrlKeys.title.progressStart] : trls[TrlKeys.title.progress],
                          {
                              '{}': (
                                  <ConversionNumber
                                      many={
                                          trls[!isProgressStart ? TrlKeys.count.many : TrlKeys.vacancyAccusative.many]
                                      }
                                      one={trls[!isProgressStart ? TrlKeys.count.one : TrlKeys.vacancyAccusative.one]}
                                      some={
                                          trls[!isProgressStart ? TrlKeys.count.some : TrlKeys.vacancyAccusative.some]
                                      }
                                      value={responsesLeft}
                                  />
                              ),
                          }
                      )
            }
            linkText={stage === LoopCounterStage.Success ? trls[TrlKeys.link.success] : trls[TrlKeys.link.progress]}
            renderIcon={renderIcon}
            linkTo={fullSearchLink}
            onClick={() => {
                if (stage === LoopCounterStage.Success) {
                    buttonClick({ buttonName: 'view_vacancies_continue_to_response_card' });
                } else {
                    buttonClick({ buttonName: 'view_vacancies_response_card' });
                }
            }}
        />
    );
};

export default translation(ResponseStreak);
