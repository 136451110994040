import { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Cell, CellText } from '@hh.ru/magritte-ui';
import { ChartLineOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Text from 'bloko/blocks/text';

import { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip, { InfoPlacement, InfoTheme } from 'lux/components/Infotip';
import translation from 'lux/components/translation';

const STATISTICS_ON_MY_EVENTS = 'statisticsOnMyEvents';
const STATISTICS_ON_INDEX_MY_EVENTS = 'statistics_on_index_my_events';

const EventStatistics = ({ trls }) => {
    const statisticsLink = useRef();

    useEffect(() => {
        Analytics.sendHHEventElementShown(statisticsLink.current, {
            name: STATISTICS_ON_INDEX_MY_EVENTS,
        });
    }, []);

    return (
        <Cell left={<ChartLineOutlinedSize24 />}>
            <span ref={statisticsLink}>
                <Infotip
                    show
                    name={STATISTICS_ON_MY_EVENTS}
                    theme={InfoTheme.Dark}
                    placement={InfoPlacement.RightStart}
                    markAsViewedEvent={MarkAsViewedEvent.OnHide}
                    render={() => (
                        <Fragment>
                            <Text Element="span" strong>
                                {trls[EventStatistics.trls.statisticsInfotipTitle]}
                            </Text>
                            <p>{trls[EventStatistics.trls.statisticsInfotipText]}</p>
                        </Fragment>
                    )}
                >
                    <Link
                        to="/employer/statistics?from=index_my_events&hhtmFromLabel=index_my_events"
                        target="_blank"
                        data-qa="my-events-statistics"
                        onClick={() => Analytics.sendHHEventButtonClick(STATISTICS_ON_INDEX_MY_EVENTS)}
                    >
                        <CellText>{trls[EventStatistics.trls.statistics]}</CellText>
                    </Link>
                </Infotip>
            </span>
        </Cell>
    );
};

EventStatistics.propTypes = {
    trls: PropTypes.object,
};

EventStatistics.trls = {
    statistics: 'index.employer.statistics',
    statisticsInfotipTitle: 'index.employer.statistics.infotip.title',
    statisticsInfotipText: 'index.employer.statistics.infotip.text',
};

export default translation(EventStatistics);
