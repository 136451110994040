import { ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { Title as MagritteTitle, VSpacing as MagritteVSpacing, Breakpoint, useBreakpoint } from '@hh.ru/magritte-ui';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import IndexCollapsibleBlock from 'lux/components/IndexCollapsibleBlock';
import translation from 'lux/components/translation';
import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';
import { ArticleItemWithTopic } from 'lux/models/article.types';

import FAQAnalytics from 'lux/components/FAQ/analytics';

import FAQItem from './FAQItem';

import styles from './faq.less';

const TrlKeys = {
    title: 'index.headers.FAQ',
    minutesCounter: 'faq.card.time.count',
    minutesOne: 'plurals.minutes.one',
    minutesSome: 'plurals.minutes.some',
    minutesMany: 'plurals.minutes.many',
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

interface FAQProps {
    items: ArticleItemWithTopic[];
    gapTop?: boolean;
    isMedium?: boolean;
    redesigned?: boolean;
    spacingBottom?: ReactNode;
    isApplicant?: boolean;
}

const ARTICLES_PREVIEW_COUNT_DEFAULT = 4;
const ARTICLES_PREVIEW_COUNT: Partial<Record<Breakpoint, number>> = {
    [Breakpoint.XS]: 3,
    [Breakpoint.M]: 6,
};
const FAQ: TranslatedComponent<FAQProps> = ({
    trls,
    gapTop = false,
    items,
    isMedium = true,
    redesigned = false,
    spacingBottom,
    isApplicant,
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const faqItemsRef = useRef(null);
    const { breakpoint } = useBreakpoint();

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && items?.length && faqItemsRef.current) {
            FAQAnalytics.faqItemsShown(
                faqItemsRef.current,
                items.map((item) => item.topic)
            );
        }
    }, [employerMainPageAnalyticsEnabled, items]);

    if (isApplicant) {
        const previewCount = ARTICLES_PREVIEW_COUNT[breakpoint] || ARTICLES_PREVIEW_COUNT_DEFAULT;
        return (
            <div>
                <MagritteTitle Element="h3" size="medium" alignment="left">
                    {trls[TrlKeys.title]}
                </MagritteTitle>
                <MagritteVSpacing default={16} />
                <IndexCollapsibleBlock
                    renderPreview={() => (
                        <div className={styles.faqWrapper}>
                            {items.slice(0, previewCount).map((item) => (
                                <FAQItem
                                    isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                    isMagritte
                                    item={item}
                                    key={item.href}
                                />
                            ))}
                        </div>
                    )}
                    renderRest={() => (
                        <div className={styles.faqWrapper}>
                            {items.slice(previewCount).map((item) => (
                                <FAQItem
                                    isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                    isMagritte
                                    item={item}
                                    key={item.href}
                                />
                            ))}
                        </div>
                    )}
                    needToExpand={items.length > previewCount}
                    restSpacing={<MagritteVSpacing default={24} />}
                    renderBottomSheetContent={() => (
                        <div className={styles.faqWrapperVertical}>
                            {items.map((item) => (
                                <FAQItem
                                    isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                    isMagritte
                                    item={item}
                                    isBottomSheetItem
                                    key={item.href}
                                />
                            ))}
                        </div>
                    )}
                    bottomSheetTitle={trls[TrlKeys.title]}
                />
                {spacingBottom}
            </div>
        );
    }

    return (
        <Gap top={gapTop}>
            {redesigned ? (
                <MagritteTitle Element="h3" size="small">
                    {trls[TrlKeys.title]}
                </MagritteTitle>
            ) : (
                <H2 Element="h3">{trls[TrlKeys.title]}</H2>
            )}
            <VSpacing base={redesigned ? 6 : 4} />
            <div
                ref={faqItemsRef}
                className={classnames('multiple-column-list', {
                    'multiple-column-list_medium-1': isMedium,
                })}
            >
                {items.map((item, index) => (
                    <FAQItem
                        isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                        item={item}
                        isRedesigned={redesigned}
                        isLastItem={index === items.length - 1}
                        isBottomSheetItem
                        key={item.href}
                    />
                ))}
            </div>

            {redesigned && <VSpacing base={6} />}
        </Gap>
    );
};

export default translation(FAQ);
