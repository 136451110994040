// tempexp_27495_file
import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSignupTrlsContextContext } from 'lux/components/SignupModal/contexts/SignupTrls';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import AgreementText from 'lux/components/SignupModal/Form/steps/sign-in/AgreementText';
import SignInFormStep from 'lux/components/SignupModal/Form/steps/sign-in/SignInForm';

import styles from './sign-in-step.less';

const TrlKeys = {
    header: 'anonymous.signup.modal.signin.title',
    secondary: 'anonymous.signup.modal.signin.secondary',
    submit: 'signupForm.create.submit',
    inputPlaceholder: 'anonymous.signup.modal.signin.input.placeholder',
};

export interface SignInStepProps {
    callbackSubmit?: () => void;
    next: () => void;
}

const SignInStep: TranslatedComponent<SignInStepProps> = ({ next, trls, callbackSubmit }) => {
    const backurl = useSelector(({ authUrl }) => authUrl?.backurl);
    const singupTrls = useSignupTrlsContextContext();

    return (
        <>
            <SignInFormStep
                isAutofocusEnabled={false}
                backurl={backurl}
                next={next}
                callbackSubmit={callbackSubmit}
                render={({ renderLogin, renderSubmitButton, renderCaptcha }) => (
                    <div>
                        <Text size={TextSize.Large} strong>
                            {singupTrls?.signin?.header || trls[TrlKeys.header]}
                        </Text>
                        <VSpacing base={1} />
                        <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                            {trls[TrlKeys.secondary]}
                            <VSpacing base={4} />
                        </Text>

                        <div className={styles.inputWrapper}>
                            {renderLogin({ placeholder: trls[TrlKeys.inputPlaceholder] })}
                        </div>
                        <VSpacing base={6} xs={1} />
                        <div className={styles.buttonWrapper}>
                            {renderSubmitButton(trls[TrlKeys.submit], {
                                scale: undefined,
                            })}
                        </div>
                        {renderCaptcha()}
                        <VSpacing base={6} />
                        <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                            <div className={styles.agreement}>
                                <AgreementText />
                            </div>
                        </Text>
                    </div>
                )}
            />
        </>
    );
};

export default translation(SignInStep);
