import { useState, useEffect, useCallback } from 'react';

import appInstallBannerCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_close_button_click';
import appInstallBannerOpenButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_open_button_click';
import appInstallBannerScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_screen_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H1Section } from 'bloko/blocks/header';
import { CrossScaleMediumEnclosedFalse, IconDynamic, IconLink, IconColor } from 'bloko/blocks/icon';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import modalHelper from 'bloko/common/modalHelper';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './mobile-app-promotion.less';

const TrlKeys = {
    title: 'mobileAppPromo.title',
    install: 'mobileAppPromo.install',
    skip: 'mobileAppPromo.skip',
};

const MobileAppPromotion: TranslatedComponent = ({ trls }) => {
    const mobileAppPromotionVisible = useSelector((state) => state.mobileAppPromotionVisible);
    const breakpoint = useBreakpoint(Breakpoint.M);
    const isXs = breakpoint === Breakpoint.XS;
    const stayInTouch = useSelector((state) => state.stayInTouch);
    const appLink = stayInTouch.apps[0]?.href;
    const [isShown, setIsShown] = useState(mobileAppPromotionVisible);
    const hhtmFrom = useSelector((state) => state.analyticsParams.hhtmSource);

    const shown = isShown && isXs && !!appLink;

    useEffect(() => {
        if (!shown) {
            return undefined;
        }
        appInstallBannerScreenShown({ hhtmFrom });
        modalHelper.disableScroll();
        return () => {
            modalHelper.enableScroll();
        };
    }, [hhtmFrom, shown]);

    const onCloseWrapper = useCallback(
        (type: string) => () => {
            setIsShown(false);
            appInstallBannerCloseButtonClick({ type, hhtmFrom });
        },
        [hhtmFrom]
    );

    const onOpenWrapper = useCallback(() => {
        setIsShown(false);
        appInstallBannerOpenButtonClick({ hhtmFrom });
    }, [hhtmFrom]);

    if (!shown) {
        return null;
    }

    const appLinkWithQuery = `${appLink}?start_banner=applicant_main`;

    return (
        <div className={styles.container}>
            <div className={styles.overlay} onClick={onCloseWrapper('overlay')} />
            <div className={styles.wrapper}>
                <div className={styles.close}>
                    <IconDynamic>
                        <IconLink onClick={onCloseWrapper('close')}>
                            <CrossScaleMediumEnclosedFalse initial={IconColor.Gray50} highlighted={IconColor.Gray60} />
                        </IconLink>
                    </IconDynamic>
                </div>
                <div className={styles.content}>
                    <div className={styles.logo} />
                    <div className={styles.title}>
                        <H1Section Element="h3">{trls[TrlKeys.title]}</H1Section>
                    </div>
                    <div className={styles.main}>
                        <Button
                            to={appLinkWithQuery}
                            target="_blank"
                            kind={ButtonKind.Primary}
                            Element={Link}
                            onClick={onOpenWrapper}
                        >
                            {trls[TrlKeys.install]}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default translation(MobileAppPromotion);
