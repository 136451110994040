import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Button, Text, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';

import AbstractVacancy from 'lux/components/IndexPageNewEmployer/components/DashboardVacancy/AbstractVacancy';
import LinkInfo from 'lux/components/IndexPageNewEmployer/components/DashboardVacancy/LinkInfo';

import styles from './dashboard-vacancy.less';

const BilledVacancy = ({ trls, vacancy, bill, giftsCount }) => {
    const billedVacancyRef = useRef();
    const isZp = useIsZarplataPlatform();
    const sendAnalyticsToMyVacancies = () => {
        Analytics.sendHHEventButtonClick('dashboard_vacancy_to_my_vacancies');
    };
    const { isXS } = useBreakpoint();

    useEffect(() => {
        Analytics.sendHHEventElementShown(billedVacancyRef.current, {
            name: 'dashboard_vacancy_fourth_state',
        });
    }, [billedVacancyRef]);

    return (
        <AbstractVacancy
            name={vacancy.name}
            statusText={
                <Text
                    data-qa="dashboard-vacancy-status-autopublication"
                    typography="subtitle-1-semibold"
                    style="secondary"
                >
                    {format(trls[BilledVacancy.trls.waitingForPayment], { '{0}': bill })}
                </Text>
            }
        >
            <div ref={billedVacancyRef} data-qa="dashboard-billed-vacancy">
                <Text typography="label-2-regular">{trls[BilledVacancy.trls.billedVacancySuggestTitle]}</Text>
                <VSpacing default={24} />
                <ul>
                    {!isZp && giftsCount > 0 && (
                        <li className={styles.dashboardVacancyListItem}>
                            <Text typography="label-2-regular" Element="span">
                                {formatToReactComponent(trls[BilledVacancy.trls.billedVacancyThirdSuggestText], {
                                    '{0}': (
                                        <LinkInfo
                                            onClick={() => {
                                                Analytics.sendHHEventButtonClick(
                                                    'dashboard_vacancy_billed_vacancy_suggestion'
                                                );
                                            }}
                                            tooltipContent={format(
                                                trls[BilledVacancy.trls.billedVacancyThirdSuggestInfoText],
                                                {
                                                    '{0}': giftsCount,
                                                }
                                            )}
                                            data-qa="dashboard-vacancy-gifts"
                                        >
                                            {format(trls[BilledVacancy.trls.billedVacancyThirdSuggestLinkText], {
                                                '{0}': giftsCount,
                                            })}
                                        </LinkInfo>
                                    ),
                                })}
                            </Text>
                        </li>
                    )}
                    <li className={styles.dashboardVacancyListItem}>
                        <Text typography="label-2-regular" Element="span">
                            {trls[BilledVacancy.trls.billedVacancyFirstSuggestText]}
                        </Text>
                    </li>
                    <li className={styles.dashboardVacancyListItem}>
                        <Text typography="label-2-regular" Element="span">
                            {trls[BilledVacancy.trls.billedVacancySecondSuggestText]}
                        </Text>
                    </li>
                </ul>
            </div>
            <VSpacing default={32} />
            <Button
                Element={Link}
                onClick={sendAnalyticsToMyVacancies}
                to="/employer/vacancies"
                data-qa="dashboard-billed-vacancy__employer-vacancies-link"
                style="accent"
                mode="secondary"
                size="medium"
                stretched={isXS}
            >
                {trls[BilledVacancy.trls.toMyVacancies]}
            </Button>
        </AbstractVacancy>
    );
};

BilledVacancy.propTypes = {
    trls: PropTypes.object,
    bill: PropTypes.string,
    giftsCount: PropTypes.number,
    vacancy: PropTypes.shape({
        draftId: PropTypes.number,
        name: PropTypes.string,
        completedFieldsPercentage: PropTypes.number,
        suitableResumesCountTotal: PropTypes.number,
        suitableResumesCountLastMonth: PropTypes.number,
    }),
};

BilledVacancy.trls = {
    waitingForPayment: 'newemployer.dashboard.recentVacancy.status.waitingForPayment',
    billedVacancySuggestTitle: 'newemployer.dashboard.billedVacancy.suggestTitle',
    billedVacancyFirstSuggestText: 'newemployer.dashboard.billedVacancy.suggest.1.text',
    billedVacancySecondSuggestText: 'newemployer.dashboard.billedVacancy.suggest.2.text',
    billedVacancyThirdSuggestText: 'newemployer.dashboard.billedVacancy.suggest.3.text',
    billedVacancyThirdSuggestLinkText: 'newemployer.dashboard.vacancyStub.suggest.3.linkText',
    billedVacancyThirdSuggestInfoText: 'newemployer.dashboard.vacancyStub.suggest.3.infoText',
    toMyVacancies: 'vacancies.groups.myVacancies',
};

export default translation(BilledVacancy);
