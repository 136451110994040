import { ReactNode } from 'react';

import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import GetAppSMS from 'lux/components/GetAppSMS';
import translation from 'lux/components/translation';
import { useCountryId, useIsRussia } from 'lux/hooks/useCountries';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { CountryId } from 'lux/models/countryId';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import AppLink from 'lux/components/StayInTouch/AppLink';
import Header, { HeaderType } from 'lux/components/StayInTouch/Header';
import MobilePageLink from 'lux/components/StayInTouch/MobilePageLink';

const TrlKeys = {
    getAppSMSLabel: 'index.getAppSMS.label',
};

interface StayInTouchProps {
    analyticsFrom?: string;
    isInline?: boolean;
}

const StayInTouch: TranslatedComponent<StayInTouchProps> = ({ trls, analyticsFrom = '', isInline }) => {
    const countryId = useCountryId();
    const isRussia = useIsRussia();
    const isZarplataPlatform = useIsZarplataPlatform();
    const { mobilePage, apps } = useSelector((state) => state.stayInTouch);
    const device = useSelector(({ device }) => device);
    const userType = useSelector((state) => state.userType);
    const isBelarus = countryId === CountryId.Belarus;
    const isEmployer = userType === UserType.Employer;

    if (!apps.length) {
        return null;
    }

    const getAppLinkHref = (appHref: string) => {
        if (!isRussia && !isBelarus) {
            return appHref;
        }
        if (isZarplataPlatform) {
            return appHref;
        }

        if (device?.type) {
            const parsedUrl = urlParser(appHref);

            parsedUrl.params = { ...parsedUrl.params, footer_banner: '' }; // eslint-disable-line camelcase

            return parsedUrl.href;
        }

        return `${mobilePage || isEmployer ? '/hrmobile' : '/mobile'}?from=main_banner_mobapps`;
    };

    const appLinks = apps.map(({ href, name }) => (
        <AppLink
            key={name}
            name={name}
            href={getAppLinkHref(href)}
            analyticCategory={isEmployer ? userType : 'mobile_application'}
            analyticName={isEmployer ? 'click_hrmobile' : name}
            analyticLabel="above_footer"
        />
    ));

    const renderContent = () => {
        const headerType = userType === UserType.Employer ? HeaderType.Employer : HeaderType.Applicant;
        if (isInline) {
            return (
                <Column xs="4" s="8" m="8" l="0">
                    <Header type={headerType} countryId={countryId} />
                    <div className="stay-in-touch stay-in-touch_inline">{appLinks}</div>
                </Column>
            );
        }
        return (
            <>
                <Header type={headerType} countryId={countryId} />
                <div className="stay-in-touch">
                    <MobilePageLink mobilePage={mobilePage} />
                    <div className="stay-in-touch__apps">{appLinks}</div>
                </div>
            </>
        );
    };

    const renderGetAppSMS = (content: ReactNode) => {
        if (!isRussia || isZarplataPlatform) {
            return null;
        }
        if (isInline) {
            return (
                <Column xs="4" s="8" m="4" l="0">
                    {content}
                </Column>
            );
        }
        return <>{content}</>;
    };

    return (
        <>
            {renderContent()}
            {renderGetAppSMS(
                <GetAppSMS
                    placeholder="+79234567890"
                    label={trls[TrlKeys.getAppSMSLabel]}
                    analyticsFrom={analyticsFrom}
                />
            )}
        </>
    );
};

export default translation(StayInTouch);
