// tempexp_27495_file
import { useState, Fragment, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import DefaultMultiAccountGrouping from 'lux/components/ApplicantSignInSignUpFlow/MultiAccountGroupingStep/MultiAccountGrouping';
import DefaultSignUp from 'lux/components/ApplicantSignInSignUpFlow/SignUpStep/SignUp';
import VerificationFormStep from 'lux/components/SignupModal/Form/steps/verification/VerificationFormStep';
import useIsIframeView from 'lux/hooks/useIsIframeView';
import { useSelector } from 'lux/modules/useSelector';

const SIGN_IN = 'SIGN_IN';
const SIGN_UP = 'SIGN_UP';
const VERIFICATION = 'VERIFICATION';
const MULTIACCOUNT_GROUPING = 'MULTIACCOUNT_GROUPING';

const ApplicantSignInSignUpFlow = ({
    SignIn,
    Verification,
    MultiAccountGrouping = DefaultMultiAccountGrouping,
    SignUp = DefaultSignUp,
    isSignupPage = false,
    onSignup,
    onNextStep = () => null,
    onClickByPassword,
    errorPageLogin = false,
}) => {
    const vrResponseHash = useSelector(({ vrSignupLogin }) => vrSignupLogin.vrResponseHash);
    const [step, setStep] = useState(vrResponseHash ? VERIFICATION : SIGN_IN);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const signUpRef = useRef();
    const isIframeView = useIsIframeView();

    const Wrapper = isSignupPage ? Column : Fragment;
    const wrapperProps = isSignupPage ? { l: '16', m: '12', s: '4', xs: '4', container: true } : {};

    useEffect(() => {
        if (!isIframeView) {
            return;
        }

        function enableLinksAfterComponentInited() {
            signUpRef.current.querySelectorAll('.vk-mini-apps-link-disable').forEach((e) => {
                e.classList.remove('vk-mini-apps-link-disable');
            });
        }

        enableLinksAfterComponentInited();
    }, [isIframeView]);

    return (
        <div ref={signUpRef}>
            {step === SIGN_IN && (
                <SignIn
                    next={() => {
                        setStep(VERIFICATION);
                        onNextStep();
                    }}
                    isSignupPage={isSignupPage}
                    onSignup={onSignup}
                    onClickByPassword={onClickByPassword}
                />
            )}
            {step === VERIFICATION && (
                <Wrapper {...wrapperProps}>
                    <VerificationFormStep
                        Verification={Verification}
                        onSignup={onSignup}
                        cancel={() => setStep(SIGN_IN)}
                        isSignupPage={isSignupPage}
                        next={(code, isMultiAccountCreation) => {
                            setStep(isSignupPage && isMultiAccountCreation ? MULTIACCOUNT_GROUPING : SIGN_UP);
                            setCode(code);
                        }}
                        errorPageLogin={errorPageLogin}
                    />
                </Wrapper>
            )}
            {step === MULTIACCOUNT_GROUPING && (
                <Wrapper {...wrapperProps}>
                    <MultiAccountGrouping
                        isSignupPage={isSignupPage}
                        next={(password) => {
                            setStep(SIGN_UP);
                            setPassword(password);
                        }}
                        errorPageLogin={errorPageLogin}
                    />
                </Wrapper>
            )}
            {step === SIGN_UP && (
                <Wrapper {...wrapperProps}>
                    <SignUp
                        code={code}
                        onSignup={onSignup}
                        password={password}
                        isSignupPage={isSignupPage}
                        errorPageLogin={errorPageLogin}
                    />
                </Wrapper>
            )}
        </div>
    );
};

ApplicantSignInSignUpFlow.propTypes = {
    SignIn: PropTypes.elementType,
    Verification: PropTypes.elementType,
    MultiAccountGrouping: PropTypes.elementType,
    SignUp: PropTypes.elementType,
    isSignupPage: PropTypes.bool,
    onSignup: PropTypes.func,
    onNextStep: PropTypes.func,
    onClickByPassword: PropTypes.func,
    errorPageLogin: PropTypes.bool,
};

export default ApplicantSignInSignUpFlow;
