import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/magritte-ui';
import { CrossScaleSmallEnclosedFalse, IconDynamic, IconLink, IconColor } from 'bloko/blocks/icon';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import { removeSavedSearch } from 'lux/models/employer/employerLastSearches';
import fetcher from 'lux/modules/fetcher';
import paths from 'lux/modules/routePaths';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import SearchDetails from 'lux/components/IndexPageEmployer/components/LastSearches/SearchDetails';

const SavedSearch = ({ trls, id, searchQuery, text, searchCriteria, position }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const handleRemove = async () => {
        dispatch(removeSavedSearch({ id }));
        try {
            await fetcher.postFormData('/shards/employer/last_search/remove', { 'search-id': id });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    };

    return (
        <li className="last-searches-item">
            <div className="last-searches-item__content">
                <span className="last-searches-item__name">
                    <Link
                        href={`${paths.resumeSearch}?${searchQuery}&mark=main_page_last_search_${position}`}
                        data-qa="last-searches-search-link"
                        style="neutral"
                        typography="paragraph-2-regular"
                    >
                        {text || trls[SavedSearch.trls.search]}
                    </Link>
                </span>
                <span className="last-searches-item__remove" data-qa="last-searches-search-remove">
                    <IconDynamic>
                        <IconLink data-search-id={id} onClick={handleRemove}>
                            <CrossScaleSmallEnclosedFalse initial={IconColor.Gray60} />
                        </IconLink>
                    </IconDynamic>
                </span>
            </div>
            <SearchDetails searchCriteria={searchCriteria} />
        </li>
    );
};

SavedSearch.trls = {
    search: 'index.employerLastSearches.search',
};

SavedSearch.propTypes = {
    trls: PropTypes.object,
    id: PropTypes.number.isRequired,
    searchQuery: PropTypes.string.isRequired,
    searchCriteria: PropTypes.object,
    position: PropTypes.number,
    text: PropTypes.string,
};

export default translation(SavedSearch);
