// tempexp_27495_file
import { MouseEventHandler } from 'react';
import classnames from 'classnames';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

interface AgreementTextProps {
    text?: string;
    link?: string;
    withSocial?: boolean;
    isIframeView?: boolean;
    target?: string;
}
const TrlKeys = {
    default: {
        text: 'anonymous.signup.modal.signin.agreement.text',
        personalLink: 'anonymous.signup.modal.signin.agreement.personal-data',
        agreementLink: 'anonymous.signup.modal.signin.agreement.rule',
    },
    zp: {
        text: 'anonymous.signup.modal.signin.agreement.text.zp',
        link: 'signup.agreementText.zp.link',
        pd: 'signup.agreementText.zp.pd',
    },
};

const agreementDataQa = 'account-signup-agreement';
const personalDataQa = 'account-signup-personal-data-russia';

const AgreementText: TranslatedComponent<AgreementTextProps> = ({ trls, isIframeView = false, ...props }) => {
    const agreementURL = useSelectorNonNullable((state) => state.authUrl['agreement-form']);
    const isLightPage = useSelector(({ isLightPage }) => isLightPage);
    const personalDataRussiaURL = useSelectorNonNullable((state) => state.authUrl['policy-url']);
    const isZp = useIsZarplataPlatform();

    if (isZp) {
        return (
            <div
                className={classnames('account-agreement-text', {
                    'account-agreement-text_light-page': isLightPage,
                })}
            >
                {formatToReactComponent(trls[TrlKeys.zp.text], {
                    '{0}': (
                        <BlokoLink Element={Link} to={agreementURL} data-qa={agreementDataQa}>
                            {trls[TrlKeys.zp.link]}
                        </BlokoLink>
                    ),
                    '{1}': (
                        <BlokoLink href="https://hhcdn.ru/file/17755941.doc" data-qa="account-signup-zp-pd">
                            {trls[TrlKeys.zp.pd]}
                        </BlokoLink>
                    ),
                })}
            </div>
        );
    }

    const handleClickForVKminiApp: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (!isIframeView) {
            return;
        }
        event.preventDefault();
        window.location.href = event.currentTarget.href;
    };

    const renderLink = (text: string, dataQa: string, to: string) => {
        return (
            <Link
                {...props}
                onClick={handleClickForVKminiApp}
                className={classnames({ 'vk-mini-apps-link-disable': isIframeView })}
                to={to}
                data-qa={dataQa}
            >
                {text}
            </Link>
        );
    };

    return (
        <div
            className={classnames('account-agreement-text', {
                'account-agreement-text_light-page': isLightPage,
            })}
        >
            {formatToReactComponent(trls[TrlKeys.default.text], {
                '{0}': renderLink(trls[TrlKeys.default.personalLink], personalDataQa, personalDataRussiaURL),
                '{1}': renderLink(trls[TrlKeys.default.agreementLink], agreementDataQa, agreementURL),
            })}
        </div>
    );
};

export default translation(AgreementText);
