import { useState } from 'react';

import {
    Banner,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Modal,
    Text,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import ActivityEventScoreList from 'lux/components/IndexPageApplicant/ApplicantActivityCard/ActivityEventScoreList/ActivityEventScoreList';
import ApplicantActivity from 'lux/components/IndexPageApplicant/ApplicantActivityCard/ApplicantActivity/ApplicantActivity';

const TrlKeys = {
    content: 'applicant.gamification.activity.hint',
    close: 'close',
};

const ApplicantActivityCard: TranslatedComponent = ({ trls }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isGtS } = useBreakpoint();
    const activity = useSelector((state) => state.applicantActivity);
    const activityEvents = useSelector((state) => state.applicantActivityEvents);

    if (!activity) {
        return null;
    }

    const score = activity.userActivityScore ?? 0;
    const eventScores = activityEvents?.eventScores ?? [];

    const handleClick = (): void => {
        setIsModalOpen(true);
    };

    const handleClose = (): void => {
        setIsModalOpen(false);
    };

    const closeButton = (
        <Button mode="secondary" onClick={handleClose}>
            {trls[TrlKeys.close]}
        </Button>
    );

    const modalContent = (
        <VSpacingContainer default={16}>
            <ApplicantActivity score={score} size="large" />
            <Banner content={<Text Element="p">{trls[TrlKeys.content]}</Text>} style="neutral" showClose={false} />
            {!!eventScores.length && <ActivityEventScoreList eventScores={eventScores} />}
        </VSpacingContainer>
    );

    return (
        <>
            <Card
                actionCard
                stretched
                showBorder
                padding={isGtS ? 24 : 16}
                borderRadius={isGtS ? 24 : 16}
                onClick={handleClick}
            >
                <ApplicantActivity
                    score={score}
                    size="small"
                    right={<ChevronRightOutlinedSize24 initial="secondary" />}
                />
            </Card>
            {isGtS ? (
                <Modal visible={isModalOpen} rightButtons={closeButton}>
                    {modalContent}
                </Modal>
            ) : (
                <BottomSheet
                    visible={isModalOpen}
                    footer={<BottomSheetFooter>{closeButton}</BottomSheetFooter>}
                    onClose={handleClose}
                >
                    <VSpacing default={12} />
                    {modalContent}
                </BottomSheet>
            )}
        </>
    );
};

export default translation(ApplicantActivityCard);
