import PropTypes from 'prop-types';

import { Card } from '@hh.ru/magritte-ui';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import EmptySearches from 'lux/components/IndexPageEmployer/components/LastSearches/EmptySearches';
import SearchList from 'lux/components/IndexPageEmployer/components/LastSearches/SearchList';

const LastSearches = ({ maxHeight }) => {
    const lastSearches = useSelector((state) => state.employerLastSearches);
    return (
        <div className="last-searches-wrapper">
            <Card showBorder padding={24} borderRadius={24} stretched>
                {lastSearches.length ? <SearchList searches={lastSearches} maxHeight={maxHeight} /> : <EmptySearches />}
            </Card>
        </div>
    );
};

LastSearches.propTypes = {
    trls: PropTypes.object,
    maxHeight: PropTypes.number,
};

export default translation(LastSearches);
