import { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Divider } from '@hh.ru/magritte-ui';
import throttle from 'bloko/common/throttle';

import { useSelector } from 'lux/modules/useSelector';

import ItemLink from 'lux/components/IndexPageEmployer/components/Updates/ItemLink';
import ItemText from 'lux/components/IndexPageEmployer/components/Updates/ItemText';
import { TAB_IDS, UpdateType } from 'lux/components/IndexPageEmployer/components/Updates/constants';

const SCROLL_THROTTLE_DELAY_MS = 50;

const calcIsShadowVisible = (parent, activeElement) =>
    activeElement.scrollHeight - activeElement.scrollTop > parent.offsetHeight;

const Body = ({ activeTabId }) => {
    const updates = useSelector((state) => state.oldEmployerUpdates);
    const updatesNew = useMemo(() => updates.filter((item) => item.type === UpdateType.New), [updates]);
    const updatesSoon = useMemo(() => updates.filter((item) => item.type === UpdateType.Soon), [updates]);

    const updatesBodyItemsRef = useRef();
    const updatesNewItemsRef = useRef();
    const updatesSoonItemsRef = useRef();

    const [shadow, showShadow] = useState(true);

    const isNewTabActive = activeTabId === TAB_IDS.NEW_TAB_ID;

    const trackScroll = throttle((element) => {
        showShadow(calcIsShadowVisible(updatesBodyItemsRef.current, element));
    }, SCROLL_THROTTLE_DELAY_MS);

    useEffect(() => {
        const activeItemsElement = isNewTabActive ? updatesNewItemsRef.current : updatesSoonItemsRef.current;
        showShadow(calcIsShadowVisible(updatesBodyItemsRef.current, activeItemsElement));
    }, [isNewTabActive]);

    return (
        <div className="updates-body">
            <div
                className={classnames('updates-body__items', { 'updates-body__items_hideshadow': !shadow })}
                ref={updatesBodyItemsRef}
            >
                <div
                    className={classnames('updates-body__items-new', {
                        'g-hidden': !isNewTabActive,
                    })}
                    ref={updatesNewItemsRef}
                    onScroll={() => {
                        trackScroll(updatesNewItemsRef.current);
                    }}
                >
                    {updatesNew.map(({ date, text, href, viewed }, index) => {
                        const isLast = index === updatesNew.length - 1;

                        const item = href ? (
                            <ItemLink date={date} text={text} href={href} viewed={viewed} />
                        ) : (
                            <ItemText date={date} text={text} />
                        );

                        return (
                            <div key={href || text}>
                                {item}
                                {!isLast && <Divider marginTop={16} marginBottom={16} />}
                            </div>
                        );
                    })}
                </div>
                <div
                    className={classnames('updates-body__items-soon', {
                        'g-hidden': isNewTabActive,
                    })}
                    ref={updatesSoonItemsRef}
                    onScroll={() => {
                        trackScroll(updatesSoonItemsRef.current);
                    }}
                >
                    {updatesSoon.map(({ date, text }, index) => {
                        const isLast = index === updatesNew.length - 1;

                        return (
                            <div key={index}>
                                <ItemText date={date} text={text} />
                                {!isLast && <Divider marginTop={16} marginBottom={16} />}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

Body.propTypes = {
    activeTabId: PropTypes.oneOf(Object.values(TAB_IDS)).isRequired,
};

export default Body;
