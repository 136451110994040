import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { useCountryId, useIsRussia } from 'lux/hooks/useCountries';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { CountryId } from 'lux/models/countryId';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

interface MobilePageLinkProps {
    mobilePage: string | null;
}

const getClassName = (countryId: CountryId, userType: UserType, isZarplataPlatform: boolean): string => {
    const isEmployer = userType === UserType.Employer;
    const isBelarus = countryId === CountryId.Belarus;
    const isRussia = countryId === CountryId.Russia;

    if (isZarplataPlatform) {
        return 'stay-in-touch__mobile_picture-zarplata';
    }

    if (isEmployer) {
        return isBelarus ? 'stay-in-touch__mobile_picture-employer-jtb' : 'stay-in-touch__mobile_picture-employer';
    }

    return isRussia ? 'stay-in-touch__mobile_animated-picture' : 'stay-in-touch__mobile_picture';
};

const MobilePageLink: FC<MobilePageLinkProps & PropsWithChildren> = ({ mobilePage }) => {
    const countryId = useCountryId();
    const isRussia = useIsRussia();
    const { host } = useSelector((state) => state.request);
    const userType = useSelector((state) => state.userType);
    const isZarplataPlatform = useIsZarplataPlatform();

    if (isZarplataPlatform) {
        return (
            <span
                className={classnames('stay-in-touch__mobile', getClassName(countryId, userType, isZarplataPlatform))}
            />
        );
    }

    if (!mobilePage) {
        return null;
    }

    const mobilePageUrl = isRussia
        ? `${mobilePage}?from=main_banner_mobapps`
        : `${mobilePage}?from=main_page_mobpromo_${host}`;

    return (
        <a
            rel="noopener noreferrer"
            target="_blank"
            href={mobilePageUrl}
            className={classnames('stay-in-touch__mobile', getClassName(countryId, userType, isZarplataPlatform))}
        />
    );
};

export default MobilePageLink;
