import { useCallback, FC, ReactNode, ComponentType } from 'react';

import ApplicantSignInSignUpFlow from 'lux/components/ApplicantSignInSignUpFlow';

import MultiAccountGroupingStep, {
    MultiAccountGroupingStepProps,
} from 'lux/components/IndexPageAnonymous/SignUpFormCompact/MultiAccountGroupingStep';
import SignInStep, { SignInStepProps } from 'lux/components/IndexPageAnonymous/SignUpFormCompact/SignInStep';
import SignUpStep, { SignUpStepProps } from 'lux/components/IndexPageAnonymous/SignUpFormCompact/SignUpStep';
import VerificationStep, {
    VerificationStepProps,
} from 'lux/components/IndexPageAnonymous/SignUpFormCompact/VerificationStep';

interface Props {
    setHeader: (headerContent: ReactNode) => void;
}

const SignUpFormCompact: FC<Props> = ({ setHeader }) => {
    const SignIn = useCallback<ComponentType<Omit<SignInStepProps, 'setHeader'>>>(
        (props) => <SignInStep {...props} setHeader={setHeader} />,
        [setHeader]
    );
    const Verification = useCallback<ComponentType<Omit<VerificationStepProps, 'setHeader'>>>(
        (props) => <VerificationStep {...props} setHeader={setHeader} />,
        [setHeader]
    );
    const MultiAccountGrouping = useCallback<ComponentType<Omit<MultiAccountGroupingStepProps, 'setHeader'>>>(
        (props) => <MultiAccountGroupingStep {...props} setHeader={setHeader} />,
        [setHeader]
    );
    const SignUp = useCallback<ComponentType<Omit<SignUpStepProps, 'setHeader'>>>(
        (props) => <SignUpStep {...props} setHeader={setHeader} />,
        [setHeader]
    );

    return (
        <ApplicantSignInSignUpFlow
            SignIn={SignIn}
            Verification={Verification}
            MultiAccountGrouping={MultiAccountGrouping}
            SignUp={SignUp}
        />
    );
};

export default SignUpFormCompact;
