import { ReactNode, useCallback, useState } from 'react';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import Form from 'lux/components/IndexPageAnonymous/SignUp/Form';

import styles from './banner.less';

const TrlKeys = {
    headerPhone: 'signup.header.phone',
};

const SignUpBanner: TranslatedComponent = ({ trls }) => {
    const logoDomain = useSelector(({ logos }) => logos.logoDomain);

    const [headerContent, setHeaderContent] = useState<ReactNode>(
        <Text size={TextSize.Large} strong>
            {trls[TrlKeys.headerPhone]}
        </Text>
    );

    const setHeader = useCallback((content: ReactNode) => {
        setHeaderContent(content);
    }, []);

    return (
        <ColumnsWrapper>
            <VSpacing base={10} xs={0} />
            <Column xs="4" s="8" m="12" l="16">
                <div className={styles.wrapper}>
                    <div className={styles.wrapperSteps}>
                        <div
                            className={classnames(styles.logo, {
                                [styles.logoJobsTutBy]: logoDomain === 'jobs-tut-by',
                                [styles.logoZp]: logoDomain === 'zarplata-ru',
                            })}
                        />
                        <VSpacing base={8} xs={5} />
                        <div className={styles.stepDescription}>{headerContent}</div>
                    </div>
                    <div className={styles.wrapperContent}>
                        <div className={styles.content}>
                            <Form setHeader={setHeader} />
                        </div>
                    </div>
                </div>
            </Column>
        </ColumnsWrapper>
    );
};

export default translation(SignUpBanner);
