import classnames from 'classnames';

import {
    Link as MagritteLink,
    Card,
    Tag,
    Text as MagritteText,
    VSpacing as MagritteVSpacing,
} from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import BlokoLink, { LinkKind as BlokoLinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { ArticleItemWithTopic } from 'lux/models/article.types';

import FAQAnalytics from 'lux/components/FAQ/analytics';

import styles from './faq.less';

interface FAQItemProps {
    item: ArticleItemWithTopic;
    isBottomSheetItem?: boolean;
    isMagritte?: boolean;
    isAnalyticsEnabled?: boolean;
    isRedesigned?: boolean;
    isLastItem?: boolean;
}

const TrlKeys = {
    minutesCounter: 'faq.card.time.count',
    minutesOne: 'plurals.minutes.one',
    minutesSome: 'plurals.minutes.some',
    minutesMany: 'plurals.minutes.many',
};

const FAQItem: TranslatedComponent<FAQItemProps> = ({
    trls,
    item,
    isBottomSheetItem = false,
    isMagritte = false,
    isAnalyticsEnabled = true,
    isRedesigned = false,
    isLastItem = false,
}) => {
    const { href, topic, tag, time } = item;
    if (isMagritte) {
        return (
            <div
                className={classnames(styles.faqCardWrapper, {
                    [styles.faqCardWrapperBottomsheet]: isBottomSheetItem,
                })}
                key={href}
            >
                <Card
                    Element={Link}
                    to={href}
                    showBorder
                    padding={24}
                    borderRadius={24}
                    stretched
                    verticalStretched
                    actionCard
                    onClick={isAnalyticsEnabled ? () => FAQAnalytics.faqItemClick(topic) : undefined}
                >
                    <div className={styles.faqCardContent}>
                        <div>
                            {!!tag && <Tag>{tag}</Tag>}
                            <MagritteVSpacing default={16} />
                            <MagritteText typography="paragraph-1-regular">{topic}</MagritteText>
                        </div>
                        {isBottomSheetItem && <MagritteVSpacing default={24} />}
                        {!!time && (
                            <MagritteText typography="label-3-regular" style="secondary">
                                <Conversion
                                    one={trls[TrlKeys.minutesOne]}
                                    some={trls[TrlKeys.minutesSome]}
                                    many={trls[TrlKeys.minutesMany]}
                                    value={time}
                                    hasValue={false}
                                    format={(trl) =>
                                        format(trls[TrlKeys.minutesCounter], {
                                            '{0}': `${time} ${trl}`,
                                        })
                                    }
                                />
                            </MagritteText>
                        )}
                    </div>
                </Card>
            </div>
        );
    }

    return (
        <div
            key={href}
            className={classnames('multiple-column-list-item', {
                'multiple-column-list-item_redesigned': isRedesigned,
            })}
        >
            <Text>
                <div
                    className={classnames({
                        'easy-tap-link': !isRedesigned,
                        'easy-tap-link_last': !isRedesigned && isLastItem,
                    })}
                >
                    {isRedesigned ? (
                        <MagritteLink
                            href={href}
                            style="neutral"
                            onClick={isAnalyticsEnabled ? () => FAQAnalytics.faqItemClick(topic) : undefined}
                            typography="label-2-regular"
                        >
                            {topic}
                        </MagritteLink>
                    ) : (
                        <BlokoLink
                            href={href}
                            kind={BlokoLinkKind.Tertiary}
                            onClick={isAnalyticsEnabled ? () => FAQAnalytics.faqItemClick(topic) : undefined}
                        >
                            {topic}
                        </BlokoLink>
                    )}
                </div>
            </Text>
        </div>
    );
};

export default translation(FAQItem);
