import { useRef, useEffect } from 'react';

import { Link, VSpacing, Title as MagritteTitle } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import indexPageEmployerAnalytics from 'lux/components/IndexPageEmployer/components/analytics';
import translation from 'lux/components/translation';
import { useIsRussia } from 'lux/hooks/useCountries';
import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';

const TrlKeys = {
    header: 'index.activityCalendar.header',
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const Title: TranslatedComponent = ({ trls }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const activitiesTitleRef = useRef(null);
    const isRussia = useIsRussia();

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && activitiesTitleRef.current) {
            indexPageEmployerAnalytics.activitiesTitleShown(activitiesTitleRef.current);
        }
    }, [employerMainPageAnalyticsEnabled]);

    if (isRussia) {
        return (
            <>
                <span ref={activitiesTitleRef}>
                    <Link
                        href="/articles/events"
                        typography="custom-1-semibold"
                        style="neutral"
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? indexPageEmployerAnalytics.activitiesTitleClick
                                : undefined
                        }
                    >
                        {trls[TrlKeys.header]}
                    </Link>
                </span>

                <VSpacing default={24} />
            </>
        );
    }

    return (
        <div ref={activitiesTitleRef}>
            <MagritteTitle Element="h2" size="small">
                {trls[TrlKeys.header]}
            </MagritteTitle>
            <VSpacing default={24} />
        </div>
    );
};

export default translation(Title);
