import { ReactNode, useEffect } from 'react';

import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SignInFormStep from 'lux/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import Agreement from 'lux/components/IndexPageAnonymous/SignUpFormCompact/Agreement';

import styles from './styles.less';

const TrlKeys = {
    header: 'signup.header.phone',
    submit: 'signupForm.create.submit',
    inputPlaceholder: 'signup.placeholder.phoneNumber',
    titleA: 'index.anonymous.signup.title.a',
    titleB: 'index.anonymous.signup.title.b',
    subtitleA: 'index.anonymous.signup.subtitle.a',
    subtitleB: 'index.anonymous.signup.subtitle.b',
};

export interface SignInStepProps {
    callbackSubmit?: () => void;
    next: () => void;
    setHeader: (headerContent: ReactNode) => void;
}

const SignInStep: TranslatedComponent<SignInStepProps> = ({ next, trls, callbackSubmit, setHeader }) => {
    const backurl = useSelector(({ authUrl }) => authUrl?.backurl);
    const isAnonymousSignupTiteExp = useSelector(({ isAnonymousSignupTiteExp }) => isAnonymousSignupTiteExp);

    useEffect(() => {
        setHeader(
            <>
                <Text size={TextSize.Large} strong>
                    {trls[isAnonymousSignupTiteExp ? TrlKeys.titleA : TrlKeys.titleB]}
                </Text>
                <VSpacing base={1} />
                <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                    {trls[isAnonymousSignupTiteExp ? TrlKeys.subtitleA : TrlKeys.subtitleB]}
                </Text>
            </>
        );
    }, [isAnonymousSignupTiteExp, setHeader, trls]);

    return (
        <>
            <SignInFormStep
                isAutofocusEnabled={false}
                backurl={backurl}
                next={next}
                callbackSubmit={callbackSubmit}
                render={({ renderLogin, renderSubmitButton, renderCaptcha }) => (
                    <>
                        <div className={styles.container}>
                            {renderLogin({ placeholder: trls[TrlKeys.inputPlaceholder] })}
                            {renderSubmitButton(trls[TrlKeys.submit], {
                                scale: undefined,
                            })}
                        </div>
                        {renderCaptcha()}
                        <VSpacing base={6} />
                        <Agreement />
                    </>
                )}
            />
        </>
    );
};

export default translation(SignInStep);
