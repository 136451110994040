import type { TextProps } from '@hh.ru/magritte-ui';

import type { ApplicantActivityContentSize } from 'lux/components/IndexPageApplicant/ApplicantActivityCard/ApplicantActivity/types';

export const MAX_ACTIVITY_SCORE = 100;

export const IconSizeMap: Record<ApplicantActivityContentSize, number> = {
    small: 12,
    large: 24,
} as const;

export const TitleTypographyMap: Record<ApplicantActivityContentSize, TextProps['typography']> = {
    small: 'subtitle-1-semibold',
    large: 'title-5-semibold',
} as const;

export const PercentTypographyMap: Record<ApplicantActivityContentSize, TextProps['typography']> = {
    small: 'label-2-regular',
    large: 'label-1-regular',
} as const;
