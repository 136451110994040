import { FC, Fragment, PropsWithChildren, useRef, useEffect } from 'react';

import { Link, Text } from '@hh.ru/magritte-ui';

import indexPageEmployerAnalytics from 'lux/components/IndexPageEmployer/components/analytics';
import useFeatureEnabled from 'lux/hooks/useFeatureEnabled';
import { useSelector } from 'lux/modules/useSelector';

import Title from 'lux/components/IndexPageEmployer/components/Calendar/Title';

interface CalendarProps {
    container?: boolean;
}

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const Calendar: FC<CalendarProps & PropsWithChildren> = () => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const { eventsMain } = useSelector((state) => state.employerIndexPage.oldEmployer) || { eventsMain: null };
    const activityItems = eventsMain?.body?.events || [];
    const activityItemsRef = useRef(null);

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && activityItems?.length && activityItemsRef.current) {
            indexPageEmployerAnalytics.activitiesItemsShown(
                activityItemsRef.current,
                activityItems.map((item) => item.text)
            );
        }
    }, [activityItems, employerMainPageAnalyticsEnabled]);

    if (!eventsMain) {
        return null;
    }

    return (
        <div ref={activityItemsRef}>
            <Title />
            {eventsMain.body.events.map(({ href, date, text }) => (
                <Fragment key={href}>
                    <Text typography="label-3-regular" style="secondary">
                        {date}
                    </Text>
                    <div className="activity-calendar-caption">
                        <Text typography="label-2-regular">
                            <Link
                                href={href}
                                style="neutral"
                                onClick={
                                    employerMainPageAnalyticsEnabled
                                        ? () => indexPageEmployerAnalytics.activitiesItemClick(text)
                                        : undefined
                                }
                            >
                                {text}
                            </Link>
                        </Text>
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

export default Calendar;
