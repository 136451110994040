import { useRef, RefObject, useEffect } from 'react';

import { CompanyOfTheDay } from 'lux/models/companiesOfTheDay';
import { sendAnythingViewAnalytics, SpyParams, WorkInCompanyLocation } from 'lux/utils/sendAdvSpyAnalytics';

type RefsMap = Record<number, HTMLElement>;

type UseCompaniesOfTheDayViewAnalytics = (
    companiesOfTheDay: CompanyOfTheDay[],
    location: WorkInCompanyLocation,
    spyParams?: SpyParams
) => RefObject<RefsMap>;

const useCompaniesOfTheDayViewAnalytics: UseCompaniesOfTheDayViewAnalytics = (
    companiesOfTheDay,
    location,
    spyParams
) => {
    const companiesRefs = useRef<RefsMap>({});

    useEffect(() => {
        companiesOfTheDay.forEach(({ id, viewUrl }) => {
            if (companiesRefs.current?.[id] && viewUrl) {
                sendAnythingViewAnalytics({ element: companiesRefs.current[id], viewUrl, location, spyParams });
            }
        });
    }, [companiesOfTheDay, location, spyParams]);

    return companiesRefs;
};

export default useCompaniesOfTheDayViewAnalytics;
