import { useCallback, useEffect, useRef, FC, ReactElement } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Text from 'bloko/blocks/text';
import { formatToReactComponent } from 'bloko/common/trl';

import { AnonymousDashboard } from 'lux/models/anonymousDashboard';
import { useSelector } from 'lux/modules/useSelector';

type GetSlogan = (dashboard: AnonymousDashboard) => ReactElement;

const getSlogan: GetSlogan = ({ bigPromoSlogan, bigPromoHighlightedTextInSlogan }): ReactElement => {
    if (!bigPromoHighlightedTextInSlogan) {
        return <Text Element="span">{bigPromoSlogan}</Text>;
    }
    return (
        <Text Element="span">
            {formatToReactComponent(bigPromoSlogan, {
                '[0]': (
                    <Text Element="span" strong>
                        {bigPromoHighlightedTextInSlogan}
                    </Text>
                ),
            })}
        </Text>
    );
};

const DashboardBigPromoFooter: FC = () => {
    const dashboard = useSelector(({ supernovaAnonymousDashboard }) => supernovaAnonymousDashboard);
    const locale = useSelector(({ locale }) => locale);
    const bigPromoContainer = useRef<HTMLDivElement>(null);
    const employerId = dashboard.bigPromoEmployerId;

    useEffect(() => {
        if (!bigPromoContainer.current) {
            return;
        }
        Analytics.sendHHEventElementShown(bigPromoContainer.current, {
            employerId,
            areaId: locale.areaId,
            domainAreaId: locale.domainAreaId,
            siteId: window.globalVars.siteId,
        });
        Analytics.sendEventElementShown(bigPromoContainer.current, 'anonymous', 'show-big-promo-link');
    }, [employerId, locale.areaId, locale.domainAreaId]);

    const sendAnalyticsEvents = useCallback(() => {
        Analytics.sendEvent('anonymous', 'click-big-promo-link', employerId);
        Analytics.sendHHEventButtonClick('big-promo-link', {
            employerId,
            areaId: locale.areaId,
            domainAreaId: locale.domainAreaId,
            siteId: window.globalVars.siteId,
        });
    }, [employerId, locale.areaId, locale.domainAreaId]);

    return (
        <div className="supernova-dashboard-footer supernova-dashboard-footer_with-big-promo" ref={bigPromoContainer}>
            <div className={`big-promo-logo-wrapper big-promo-logo-wrapper_${employerId}`}>
                <div className={`big-promo-logo big-promo-logo_${employerId}`} />
            </div>
            <div className={`big-promo-slogan big-promo-slogan_${employerId}`}>{getSlogan(dashboard)}</div>
            <div className={`big-promo-link-wrapper big-promo-link-wrapper_${employerId}`}>
                <a
                    href={dashboard.bigPromoLinkUrl}
                    className="supernova-link supernova-link_with-line"
                    onClick={sendAnalyticsEvents}
                    target="_blank"
                    rel="noreferrer"
                >
                    {dashboard.bigPromoLinkText}
                </a>
            </div>
            {dashboard.bigPromoFootnote && (
                <div className={`big-promo-footnote big-promo-footnote_${employerId}`}>
                    {dashboard.bigPromoFootnote}
                </div>
            )}
        </div>
    );
};

export default DashboardBigPromoFooter;
